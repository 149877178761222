import React from 'react';
import {
  Paper,
  Grid,
  Button,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  TablePagination,
  Box,
  Tab,
  Tabs
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ListItem from '@material-ui/core/ListItem';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CompanyIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment'
import dataStorage from '@s/dataStorage';
import { getOpeningAccountUrl, getBusinessInfoUrl } from '@s/api/index'
import { deleteData, getData } from '@s/api/request'
import ChooseAccountType from './ChooseAccountType';
import { ACCOUNT_TYPE } from '@Common/constants';
import { getRelativeTime, getDraft, getAccountStatus, isBroker } from '@s/helper/utils'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 2)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    minHeight: '400px',
    padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'space-between'
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper:first-child': {
      marginLeft: 0,
      '& button': {
        marginLeft: 0
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  listContainer: {
    display: 'flex',
    flex: 1,
    minHeight: 0,
    overflow: 'auto',
    flexDirection: 'column'
  },
  paginate: {
    display: 'flex',
    justifyContent: 'center'
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const DEFAULT_PAGINATION = {
  current_page: 1,
  total_count: 1,
  total_pages: 1
}

const TABS_OPTIONS = ['not_submitted', 'submitted']

export default function ChooseDraft() {
  const classes = useStyles();
  const [, updateState] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [already, setAlready] = React.useState(false)
  const [tabIndex, setTab] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const pagination = React.useRef(dataStorage.paginateDraft)
  const pageSize = React.useRef(20)
  const pageId = React.useRef(0)
  const listData = React.useRef(dataStorage.listDraft.sort((a, b) => b.data.updated - a.data.updated));

  const continueWithExistedDraft = async () => {
    try {
      dataStorage.dicDraft = listData.current[selectedIndex]
      dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
      if (dataStorage.dicDraft?.data?.index) {
        dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0
        dataStorage.isSubApplicant = true
      }
      dataStorage.registerEmail = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.dicDraft?.data?.index]?.applicant_email
      dataStorage.applicantId = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.dicDraft?.data?.index]?.applicant_id
      const equixId = dataStorage.dicDraft?.data?.formData?.equix_id
      dataStorage.equixId = equixId
      if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId)
      if (dataStorage.accountType.value) dataStorage.accountType = dataStorage.accountType.value
      if (dataStorage.accountType === ACCOUNT_TYPE.COMPANY) {
        setLoading(true)
        const verificationId = dataStorage.dicDraft.data.formData?.verificationId
        const url = getBusinessInfoUrl()
        getData(`${url}/${verificationId}`)
          .then(res => {
            setLoading(false)
            const formData = { ...dataStorage.dicDraft.data.formData, ...res }
            dataStorage.dicDraft.data.formData = formData
            setAlready(true)
          })
          .catch(() => {
            setLoading(false)
            console.error('cannot get newest company verification info')
            setAlready(true)
          })
      } else {
        setAlready(true)
      }
    } catch (error) {
      console.error('continueWithExistedDraft', error)
    }
  }

  const continueWithNewDraft = () => {
    dataStorage.dicDraft = {}
    dataStorage.accountType = null
    dataStorage.equixId = null
    dataStorage.indexApplicant = 0
    dataStorage.isSubApplicant = false
    dataStorage.accountStatus = ''
    setAlready(true)
  }

  const _backChooseDraft = () => {
    setAlready(false)
    dataStorage.equixId = null
    dataStorage.indexApplicant = 0
    dataStorage.isSubApplicant = false
    dataStorage.accountStatus = ''
    pageSize.current = 20
    pageId.current = 0
    const path = !isBroker() ? `?page_size=${pageSize.current}&page_id=${pageId.current + 1}` : `?status=not_submitted&page_size=${pageSize.current}&page_id=${pageId.current + 1}`
    setLoading(true)
    setTab(0)
    getDraft(path).then(() => {
      listData.current = dataStorage.listDraft.sort((a, b) => b.data.updated - a.data.updated)
      pagination.current = dataStorage.paginateDraft
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const onDeleteDraft = (event, item, index) => {
    event.stopPropagation()
    if (!item.id) return;
    listData.current.splice(index, 1)
    if (index === selectedIndex) {
      if (listData.current.length) forceUpdate()
      else continueWithNewDraft()
    } else forceUpdate()
    const url = getOpeningAccountUrl(`/draft?draft_id=${item.id}`)
    deleteData(url)
      .then((res) => {
        console.log('onDeleteDraft success: ', item.name)
      }).catch(error => {
        console.error(`onDeleteDraft ${item.name} error ${error}`)
        dataStorage.showError && dataStorage.showError(error)
      })
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const getListDraft = (index = tabIndex) => {
    const path = !isBroker() ? `?page_size=${pageSize.current}&page_id=${pageId.current + 1}` : `?status=${TABS_OPTIONS[index]}&page_size=${pageSize.current}&page_id=${pageId.current + 1}`
    setLoading(true)
    getDraft(path).then(() => {
      listData.current = dataStorage.listDraft.sort((a, b) => b.data.updated - a.data.updated)
      pagination.current = dataStorage.paginateDraft
      setLoading(false)
    }).catch(() => {
      listData.current = []
      pagination.current = DEFAULT_PAGINATION
      setLoading(true)
    })
  }

  const handleChangePage = (event, newPage) => {
    pageId.current = newPage
    getListDraft()
  };

  const onChangePage = (event, newPage) => {
    pageId.current = newPage - 1
    getListDraft()
  };

  const handleChangeRowsPerPage = (event) => {
    pageSize.current = +event.target.value
    pageId.current = 0
    getListDraft()
  };

  const onChangeTab = (event, index) => {
    setTab(index)
    getListDraft(index)
  };

  const renderData = (tab) => {
    if (tabIndex !== tab) return <React.Fragment />
    if (loading) {
      return <div className={classes.loadingContainer}>
        <CircularProgress
          size={40}
        />
      </div>
    }
    return (
      <React.Fragment>
        <TablePagination
          component="div"
          count={pagination.current.total_count}
          page={pageId.current}
          rowsPerPageOptions={[20, 50, 100]}
          rowsPerPage={pageSize.current}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <List className={classes.listContainer}>
          {listData.current.map((e, index) => {
            const accountType = e.data?.formData?.account_type
            return (
              <ListItem
                button
                className={classes.listItem}
                selected={selectedIndex === index}
                key={`${index}`}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon style={{ marginRight: 8 }}>
                  {accountType === ACCOUNT_TYPE.COMPANY ? <CompanyIcon /> : accountType === ACCOUNT_TYPE.JOINT ? <GroupIcon /> : <PersonIcon />}
                </ListItemIcon>
                <ListItemText primary={`${e.name} (Last Update ${moment(e.data.updated).format('DD/MM/YY HH:mm:ss')}, ${getRelativeTime(e.data.updated)})`} />
                {
                  !isBroker()
                    ? <React.Fragment />
                    : <ListItemIcon>
                      <DeleteIcon className={classes.deleteIcon} onClick={(event) => onDeleteDraft(event, e, index)} />
                    </ListItemIcon>
                }
              </ListItem>
            )
          }
          )}
        </List>
        <Pagination
          onChange={onChangePage}
          className={classes.paginate}
          page={pageId.current + 1}
          count={pagination.current.total_pages}
          shape="rounded" />
      </React.Fragment>
    )
  }

  if (already) return <ChooseAccountType backChooseDraft={_backChooseDraft} />

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        <React.Fragment>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={onChangeTab} aria-label="basic tabs example">
              <Tab label="Select or Create New Draft" id={'draft_tab_1'} />
              <Tab label="Completed Application" id={'draft_tab_2'} />
            </Tabs>
          </Box>
          {renderData(0)}
          {renderData(1)}
        </React.Fragment>
        <div className={classes.buttons}>
          <div className={classes.leftButtons}>
          </div>
          <div className={classes.rightButtons}>
            <div className={classes.wrapper}>
              {
                !isBroker()
                  ? <React.Fragment />
                  : <Button
                    onClick={() => continueWithNewDraft()}
                    variant="contained"
                    className={classes.button}
                  >
                    {'Create New'}
                  </Button>
              }
              <Button
                disabled={loading}
                onClick={() => continueWithExistedDraft()}
                type='submit'
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {'Resume'}
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </React.Fragment >
  )
}
