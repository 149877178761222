import React from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import FullApplicationDetailsReview from './FullApplicationDetailsReview';

export default function ReviewApplication() {
  const { values: formValues } = useFormikContext();

  return (
    <React.Fragment>
      <FullApplicationDetailsReview formValues={formValues} />
    </React.Fragment>
  );
}
