/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import {
    Stepper,
    Paper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import moment from 'moment'
import ApplicationDetails from './Forms/ApplicationDetails';
import TrustDetails from './Forms/TrustDetails';
import CompanyDetails from './Forms/CompanyDetails';
import AdditionalInformation from './Forms/AdditionalInformation';
import ReviewApplication from './Forms/ReviewApplication';
import ApplicationResult from './Forms/ApplicationResult';
import ApplicationDeleted from './Forms/ApplicationDeleted';

import validationSchema from './FormModels/formValidation';
import formModel from './FormModels/formModels';
import initialValues from './FormModels/formInitial'

import useStyles from '../styles';
import dataStorage from '@s/dataStorage';
import {
    createDraftOtherApplicant,
    saveDraft,
    clone,
    getMobilePhoneValue,
    isMyApplicant,
    getAddressData,
    mapDataAddress,
    mapManualAddress,
    checkSubmitted,
    getEnv,
    isBroker,
    checkKycVerify,
    isMorrison,
    scrollToTop
} from '@s/helper/utils';
import {
    getSessionUrl,
    getAuthUrl,
    getRefreshUrl,
    getDecodeUrl,
    getOpeningAccountUrl,
    getBusinessInfoUrl,
    getPerformSearchUrl
} from '@s/api/index'
import { postData } from '@s/api/request'
import at from 'lodash/at'
import {
    ACCOUNT_TYPE,
    BANK_ACCOUNT_TYPE,
    CMT_PROVIDER,
    TRANSACTION_TYPE,
    GOVERNMENT_ID_TYPE,
    CMA,
    OCCUPATION_TYPE,
    SETTLEMENT_METHOD,
    MEDICARE_CARD_COLOUR,
    ACCOUNT_STATUS,
    ACCOUNT_STATUS_DISPLAY
} from '@Common/constants'
import { v4 as uuidv4 } from 'uuid';
import DeleteApplicationButton from '@Components/DeleteApplicationButton'
import FocusError from '@Components/FocusError'
import CryptoJS from 'react-native-crypto-js';

const { formId, formField } = formModel;

const checkShow = (listDepend, formValues) => {
    if (typeof listDepend === 'object' && Object.keys(listDepend).length) {
        const keys = Object.keys(listDepend)
        for (let index = 0; index < keys.length; index++) {
            const depend = at(formValues, keys[index])[0] ?? {}
            const value = depend.value || depend
            if (value !== listDepend[keys[index]]) return false
        }
    }
    return true
}

const STEP_TRUST_INDIVIDUAL = ['Trust Details', 'Applicant Details', 'Additional Information', 'Review']
const STEP_TRUST_COMPANY = ['Trust Details', 'Company Details', 'Applicant Details', 'Additional Information', 'Review']

export default function Trust(props) {
    const { data = {} } = dataStorage.dicDraft;
    let { formData = {}, step = 0, indexApplicant = 0, isSubmitted = false } = data;
    const [steps, setStep] = React.useState(formData.trustee_type?.value === ACCOUNT_TYPE.COMPANY ? STEP_TRUST_COMPANY : STEP_TRUST_INDIVIDUAL);
    const validateObj = validationSchema;
    if (isSubmitted) step = steps.length - 1
    if (indexApplicant) dataStorage.indexApplicant = indexApplicant
    if (formData.equixId) dataStorage.equixId = formData.equix_id || formData.equixId
    const classes = useStyles();
    const refNext = React.useRef(null)
    const applicantDetailRef = React.useRef()
    const listStepCanPress = React.useRef({ 0: true, 1: step > 0, 2: step > 1, 3: step > 2 })
    const [activeStep, setActiveStep] = useState(step);
    const currentValidationSchema = validateObj[steps[activeStep]];
    const isLastStep = activeStep === steps.length - 1;
    const initialData = React.useRef({ ...initialValues, ...formData });
    const trusteeType = React.useRef(initialData.trustee_type?.value || ACCOUNT_TYPE.INDIVIDUAL)
    if (!dataStorage.equixId && dataStorage.registerEmail) {
        initialData.current.applicant_details[0].applicant_email = dataStorage.registerEmail
    }

    function _renderStepContent(values) {
        const screen = steps[activeStep]
        switch (screen) {
            case 'Trust Details':
                return <TrustDetails />;
            case 'Company Details':
                return <CompanyDetails />;
            case 'Applicant Details':
                return <ApplicationDetails ref={applicantDetailRef} />;
            case 'Additional Information':
                return <AdditionalInformation />;
            case 'Review':
                return <ReviewApplication />;
            default:
                return <div>Not Found</div>;
        }
    }

    const clearTrashFieldAndMapData = (obj = {}, path = '', formValues) => {
        try {
            Object.keys(obj).forEach(field => {
                const pathField = `${path ? path + '.' : ''}${field}`
                let item = obj[field]
                if (![undefined, null, ''].includes(item)) {
                    if (Array.isArray(item)) {
                        item.forEach((e, i) => {
                            clearTrashFieldAndMapData(e, `${pathField}[${i}]`, e)
                        })
                    } else {
                        if (field === 'role_in_trust') return
                        if (typeof item === 'object' && Object.keys(item).length && (!Object.prototype.hasOwnProperty.call(item, 'label') && !Object.prototype.hasOwnProperty.call(item, 'value') && !Object.prototype.hasOwnProperty.call(item, 'full_address'))) return clearTrashFieldAndMapData(item, pathField, formValues)
                        if (typeof item === 'object' && Object.keys(item).length && Object.prototype.hasOwnProperty.call(item, 'label') && Object.prototype.hasOwnProperty.call(item, 'value')) {
                            item = item.value
                            obj[field] = item
                        }
                        const fieldModel = at(formField, pathField.replace(/\[\d]/, ''))[0] ?? {}
                        if (fieldModel && fieldModel.dependentShow) {
                            if (!checkShow(fieldModel.dependentShow, formValues)) {
                                delete obj[field]
                            }
                        }
                    }
                } else delete obj[field]
            })
        } catch (error) {
            console.error('clearTrashFieldAndMapData error ', error)
        }
    }

    async function _submitForm(values, actions) {
        const sessionId = +new Date()
        const sessionUrl = getSessionUrl(sessionId);
        const { data: getSessonUrl } = await postData(sessionUrl);
        const obj = clone(values)
        const getHashPassword = CryptoJS.AES.encrypt(obj.password, getSessonUrl.key).toString();
        obj.password = getHashPassword;
        obj.session_id = sessionId + '';
        delete obj.confirmPassword
        clearTrashFieldAndMapData(obj, '', values)

        obj.tos_ip = window.ipPublic;
        obj.tos_user_agent = navigator.userAgent
        if (obj.company_date_of_incorporation) obj.company_date_of_incorporation = moment(obj.company_date_of_incorporation, moment.ISO_8601).format('DD/MM/YYYY')
        if (obj.company_country_of_incorporation) obj.company_country_of_incorporation = 'AUSTRALIA'
        obj.company_mobile_phone && (obj.company_mobile_phone = getMobilePhoneValue(obj.company_mobile_phone))
        obj.trade_confirmations.length = obj.applicant_details.length
        const listAddressId = obj.applicant_details.reduce((acc, cur) => {
            if (!cur.manual_address && cur.residential_address_full_address?.id) {
                acc.push(cur.residential_address_full_address?.id)
            }
            if (cur.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER && cur.business_owner_trading_address_full_address?.id) {
                acc.push(cur.business_owner_trading_address_full_address?.id)
            }
            return acc
        }, [])

        if (obj.company_registered_office_address_full_address?.id) {
            listAddressId.push(obj.company_registered_office_address_full_address?.id)
        }
        if (obj.company_principal_place_of_business_address_full_address?.id && !obj.company_same_as_roa) {
            listAddressId.push(obj.company_principal_place_of_business_address_full_address?.id)
        }

        if (listAddressId.length) {
            await getAddressData(listAddressId)
        }
        const otherData = []
        obj.applicant_details.forEach((e, i) => {
            if (!e.applicant_id) e.applicant_id = uuidv4()
            // e.applicant_id = uuidv4()
            // trade_confirmations
            e.applicant_email = e.applicant_email?.trim()
            if (!obj.trade_confirmations[i]) obj.trade_confirmations[i] = {}
            obj.trade_confirmations[i].method = 'EMAIL';
            obj.trade_confirmations[i].email = e?.applicant_email;
            obj.trade_confirmations[i].client_address = isBroker() ? true : e.client_address;
            delete e.client_address
            delete e.total_confirm
            delete e.morrison_confirm
            delete e.terms_confirm
            delete e.quant_edge_privacy_statement_confirm
            delete e.macquarie_confirm
            delete e.financial_services_guide_confirm
            delete e.verification_id
            delete e.ekyc_overall_status

            // handle tax
            if (!e.australian_tax_resident) e.tax_exemption = false

            e.role_in_trust = Object.values(e.role_in_trust || {}).map(e => (e.value || e)).join(', ')

            dataStorage.applicantId = e.applicant_id
            if (e.government_id.type === GOVERNMENT_ID_TYPE.MEDICARE_CARD) {
                e.government_id.medicare_name_on_card = e.first_name + ' ' + e.last_name;
                const expireDate = e.government_id.medicare_card_expiry_date
                if (e.government_id.medicare_card_colour === MEDICARE_CARD_COLOUR.GREEN) {
                    e.government_id.medicare_card_expiry_date = moment(expireDate, moment.ISO_8601).format('MM/YYYY')
                } else {
                    e.government_id.medicare_card_expiry_date = moment(expireDate, moment.ISO_8601).format('DD/MM/YY')
                }
            } else {
                e.government_id.first_name = e.first_name;
                e.government_id.last_name = e.last_name;
            }
            e.government_id = [e.government_id]

            // handle address
            if (e.manual_address) {
                mapManualAddress(e, e)
            } else {
                mapDataAddress(e, e.residential_address_full_address?.id, e.same_as_ra, 'residential_address')
            }
            if (e.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
                mapDataAddress(e, e.business_owner_trading_address_full_address?.id, false, 'business_owner_trading_address')
            }
            delete e.manual_address
            e.same_as_ra = true;
            e.relationship_type = 'OWNER';
            e.residential_address_country = 'AUSTRALIA'
            e.country_of_birth = 'AUSTRALIA'
            // eslint-disable-next-line no-unused-expressions
            e.applicant_mobile_phone ? (e.applicant_mobile_phone = getMobilePhoneValue(e.applicant_mobile_phone)) : ''
            e.dob = moment(e.dob, moment.ISO_8601).format('DD/MM/YYYY')
        })

        if (obj.company_same_as_roa) {
            mapDataAddress(obj, obj.company_registered_office_address_full_address?.id, false, 'company_principal_place_of_business_address')
        } else {
            mapDataAddress(obj, obj.company_principal_place_of_business_address_full_address?.id, false, 'company_principal_place_of_business_address')
        }
        mapDataAddress(obj, obj.company_registered_office_address_full_address?.id, false, 'company_registered_office_address')

        // new cma account and settlement_method
        obj.new_cma = !obj.use_existing_CMT_acc
        obj.settlement_method = obj.settlement_method ? SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER : SETTLEMENT_METHOD.SPONSORED_NEW_HIN
        obj.settlement_existing_hin && (obj.settlement_existing_hin = +obj.settlement_existing_hin)
        if (obj.new_cma) {
            obj.new_cma = CMA.CREATE_NEW
            obj.bank_account_type = BANK_ACCOUNT_TYPE.BANK_ACCOUNT;
            obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
        } else {
            obj.new_cma = CMA.USE_EXISTING
            obj.bank_account_type = BANK_ACCOUNT_TYPE.LINKED_CMT_CMA;
            obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
        }
        obj.bank_transaction_type = TRANSACTION_TYPE.BOTH

        // delete other fields
        delete obj.use_existing_CMT_acc
        delete obj.equixId

        // link draft id with submit object
        dataStorage.dicDraft.id && (obj.draft_id = dataStorage.dicDraft.id)
        // debugger;//eslint-disable-line
        const url = getOpeningAccountUrl('/trust')
        delete obj.equix_id
        delete obj.submit_time

        obj.account_type = `${obj.account_type}_${obj.trustee_type}`
        delete obj.trustee_type
        postData(url, obj).then((res) => {
            window.onbeforeunload = null; // remove popup close tab
            // console.log(res, 'test1')
            dataStorage.applicantInfo = res || { ...dataStorage.applicantInfo, ...values }
            actions.setSubmitting(false);
            setActiveStep(activeStep + 1);
            let id
            if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
                id = dataStorage.listDraft[0]?.id
            } else {
                id = dataStorage.dicDraft?.id
            }
            saveDraft({
                formData: {
                    ...values,
                    equix_id: dataStorage.equixId,
                    submit_time: +new Date(),
                    tos_ip: obj.tos_ip,
                    tos_user_agent: obj.tos_user_agent
                },
                isSubmitted: true,
                step: activeStep,
                index: 0,
                indexApplicant: dataStorage.indexApplicant,
                id
            })
        }).catch(error => {
            console.log('submit opening account error: ', error)
            dataStorage.showError && dataStorage.showError(error)
            actions.setSubmitting(false);
        })
    }

    const onDelete = () => {
        setActiveStep(-1)
    }

    const onNext = (values, actions) => {
        scrollToTop();
        let id
        if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
            id = dataStorage.listDraft[0]?.id
        } else {
            id = dataStorage.dicDraft?.id
        }
        listStepCanPress.current[activeStep + 1] = true
        saveDraft({
            formData: {
                ...values,
                equix_id: dataStorage.equixId
            },
            step: activeStep + 1,
            index: dataStorage.activeIndex,
            indexApplicant: dataStorage.indexApplicant,
            id
        })
        actions.setTouched({});
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
    }

    const onStepClick = (index) => {
        if (listStepCanPress.current[index]) {
            setActiveStep(index)
        } else {
            if (index < activeStep) {
                _handleBack()
            } else if (index > activeStep) {
                refNext.current && refNext.current.click()
            }
        }
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            onNext(values, actions)
        }
    }

    function _backChooseDraft() {
        dataStorage.equixId = null
        dataStorage.indexApplicant = 0
        dataStorage.isSubApplicant = false
        dataStorage.accountStatus = ''
        props.backChooseDraft && props.backChooseDraft()
    }

    function _handleBack(setFieldValue) {
        const func = () => {
            if (activeStep === 0) {
                props.backChooseAccountType && props.backChooseAccountType()
            } else {
                scrollToTop()
                setActiveStep(activeStep - 1);
            }
        }
        if (steps[activeStep] !== 'Applicant Details') {
            func()
        } else {
            applicantDetailRef.current && applicantDetailRef.current.checkBack(func)
        }
    }

    const renderBackDraftButton = () => {
        if ((dataStorage.listDraft.length < 2 && !isBroker()) || dataStorage.isOperatorSupport) return <React.Fragment />
        return (
            <div className={classes.wrapper} align="left">
                <Button
                    onClick={_backChooseDraft}
                    variant="contained"
                    className={classes.button}
                >
                    {'Back To Draft List'}
                </Button>
            </div>
        )
    }

    const checkCanSubmit = (values) => {
        let check = true
        const checkIndex = isBroker() ? 0 : (dataStorage.isSubApplicant ? dataStorage.activeIndex : 0)
        const element = values.applicant_details[checkIndex];
        const checkTerms = isMorrison() ? !element.terms_confirm : (!element.terms_confirm || !element.morrison_confirm)
        if (!element.client_address || !element.total_confirm || !element.macquarie_confirm || !element.quant_edge_privacy_statement_confirm || checkTerms) {
            check = false
        }
        return !isLastStep || check
    }

    const renderButtons = (isSubmitting, values, actions) => {
        const accountType = dataStorage.accountType
        if (!accountType) return <React.Fragment />
        if (checkSubmitted()) {
            return (<div className={classes.buttons}>
                <div className={classes.leftButtons}>
                    {renderBackDraftButton()}
                    <div style={{ width: 8 }} />
                    <DeleteApplicationButton onDelete={onDelete} />
                </div>
            </div>)
        }
        const isAccepted = checkCanSubmit(values);
        return (
            <div className={classes.buttons}>

                {/* Back to Draft Button is on left handside */}
                <div className={classes.leftButtons}>
                    {renderBackDraftButton()}
                    <div style={{ width: 8 }} />
                    <DeleteApplicationButton onDelete={onDelete} />
                </div>
                <div className={classes.rightButtons}>
                    {accountType && (
                        <div className={classes.wrapper}>
                            <Button onClick={() => _handleBack(actions.setFieldValue)} className={classes.button}>
                                Back
                            </Button>
                        </div>
                    )}
                    <div className={classes.wrapper}>
                        <Button
                            ref={refNext}
                            disabled={isSubmitting || !isAccepted}
                            onClick={() => {
                                if (steps[activeStep] === 'Applicant Details') {
                                    applicantDetailRef.current && applicantDetailRef.current.checkNext(() => onNext(values, actions))
                                } else actions.submitForm()
                            }}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            {isLastStep ? 'Submit Application' : 'next'}
                            {isSubmitting && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper} elevation={15} >
                {
                    activeStep === -1
                        ? <ApplicationDeleted />
                        : (
                            activeStep === steps.length
                                ? <ApplicationResult />
                                : (
                                    <React.Fragment>
                                        <Typography id='topTrust' component="h1" variant="h6" align="center">
                                            {dataStorage.accountStatus ? (ACCOUNT_STATUS_DISPLAY[dataStorage.accountStatus] || dataStorage.accountStatus) : 'NEW TRADING ACCOUNT'}
                                        </Typography>
                                        {/* navigation */}
                                        <div className={classes.container}>
                                            <Stepper
                                                orientation='vertical'
                                                activeStep={activeStep} className={classes.stepperVertical}>
                                                {steps.map((label, i) => (
                                                    <Step key={label} onClick={() => onStepClick(i)} className={listStepCanPress.current[i] ? 'stepActive' : ''}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                            <Stepper
                                                activeStep={activeStep} className={classes.stepperHorizontal}>
                                                {steps.map((label, i) => (
                                                    <Step key={label} onClick={() => onStepClick(i)} className={listStepCanPress.current[i] ? 'stepActive' : ''}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                            <div className={classes.formContainer}>
                                                <Formik
                                                    initialValues={initialData.current}
                                                    validationSchema={currentValidationSchema}
                                                    validateOnBlur={true}
                                                    validateOnChange={false}
                                                    onSubmit={_handleSubmit}
                                                >
                                                    {({ isSubmitting, setFieldValue, errors, values, setTouched, validateField, submitForm, setSubmitting, validateForm }) => {
                                                        console.log('YOLO errors: ', errors)
                                                        if (trusteeType.current !== values.trustee_type?.value) {
                                                            trusteeType.current = values.trustee_type?.value
                                                            const newStep = trusteeType.current === ACCOUNT_TYPE.COMPANY ? STEP_TRUST_COMPANY : STEP_TRUST_INDIVIDUAL
                                                            setStep(newStep)
                                                        }
                                                        return (
                                                            <FocusError>
                                                                <Form id={formId} autoComplete='off'>
                                                                    {_renderStepContent(values)}
                                                                    {renderButtons(isSubmitting, values, { setFieldValue, submitForm, setTouched, setSubmitting, validateForm })}
                                                                </Form>
                                                            </FocusError>
                                                        )
                                                    }}
                                                </Formik>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
            </Paper>
        </React.Fragment>
    );
}
