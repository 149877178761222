import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
    FormLabel,
    InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        '& .MuiFormControlLabel-root': {
            width: 'max-content',
            '& > span': {
                '&:first-child': {
                    height: 'fit-content'
                }
            }
        }
    }
}));

export default function MultipleSelectField(props) {
    const classes = useStyles();
    const { label, data, ...rest } = props;
    const [field, meta, helper] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const { setValue } = helper;
    const isError = touched && error && true;
    const timeoutid = React.useRef()

    function _renderHelperText() {
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    function onChange(e) {
        const value = e.target.value
        timeoutid.current && clearTimeout(timeoutid.current)
        timeoutid.current = setTimeout(() => {
            const obj = Object.assign((field.value || {}), { OTHER: { value, label: value } || '' })
            setValue(obj);
        }, 500)
    }

    const _onChange = (option) => (event) => {
        const obj = Object.assign((field.value || {}), { [option.value]: event.target.checked ? option : '' })
        setValue(obj);
    }

    return (
        <FormControl className={classes.formControl} {...rest} error={isError}>
            <FormLabel component="legend" style={{ marginBottom: 8 }}>{label}</FormLabel>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    {
                        data.map((e, i) => {
                            if (e.value === 'OTHER') {
                                return <></>
                            }
                            return (
                                <FormControlLabel
                                    value={e.value}
                                    checked={!!(field.value && field.value[e.value])}
                                    control={<Checkbox {...field} onChange={_onChange(e)} />}
                                    label={e.label}
                                />
                            )
                        })
                    }
                </div>
                <TextField
                    variant="standard"
                    type="text"
                    error={touched && error && true}
                    style={{ marginBottom: 16, width: '50%' }}
                    onChange={onChange}
                    defaultValue={field.value && field.value.OTHER}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                Other:&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {_renderHelperText()}
        </FormControl>
    );
}
