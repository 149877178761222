import React from 'react';
import {
    Grid,
    Typography,
    Card,
    CardActionArea,
    CardContent,
    CardMedia
} from '@material-ui/core';
import dataStorage, { initialStorage } from '@s/dataStorage';
import {
    ACCOUNT_TYPE
} from '@Common/constants'
import Individual from './Individual'
import Joint from './Joint'
import Company from './Company'
import Trust from './Trust'
import { makeStyles } from '@material-ui/core/styles';
import {
    saveDraft,
    isBroker
} from '@s/helper/utils'

const useStyle = makeStyles(theme => ({
    media: {
        marginTop: '15%',
        marginLeft: '30%',
        width: '40% !important'
    },
    styleContainer: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}))

export default function ChooseAccountType({ backChooseDraft }) {
    const classes = useStyle();
    const [accountType, setAccountType] = React.useState(dataStorage.accountType)

    const handleListItemClick = (event, accountType) => {
        dataStorage.accountType = accountType
        if (isBroker()) {
            saveDraft({
                formData: {
                    account_type: accountType
                }
            }, () => {
                setAccountType(accountType)
            })
        } else {
            if (dataStorage.dicDraft?.id) {
                const id = dataStorage.dicDraft?.id
                dataStorage.dicDraft = {
                    data: {
                        formData: {
                            account_type: accountType.current
                        }
                    },
                    id
                }
                saveDraft({
                    formData: {
                        account_type: accountType.current
                    },
                    id
                }, () => {
                    setAccountType(accountType)
                })
            } else {
                saveDraft({
                    formData: {
                        account_type: accountType.current
                    }
                }, () => {
                    setAccountType(accountType)
                })
            }
        }
    };
    function renderChooseAccountType() {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h5' style={{ marginBottom: 24, textAlign: 'center' }}>Select the account type you would like to apply for:</Typography>
            <Grid container spacing={5} className={classes.styleContainer}>
                <Grid container item xs={12} sm={3} direction="row" justifyContent="center" alignItems="center">
                    {/* Individual */}
                    <Card
                        key='individual-account'
                        onClick={(event) => handleListItemClick(event, ACCOUNT_TYPE.INDIVIDUAL)}
                    >
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="Individual"
                                image="/images/male3.png"
                                title="Individual"
                            />
                            <CardContent
                                align="center"
                            >
                                <Typography gutterBottom variant="h5">
                                    Individual
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={3} direction="row" justifyContent="center" alignItems="center">
                    {/* Joint */}
                    <Card
                        key='joint-account'
                        onClick={(event) => handleListItemClick(event, ACCOUNT_TYPE.JOINT)}
                    >
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="Joint"
                                image="/images/group3.png"
                                title="Joint"
                            />
                            <CardContent
                                align="center"
                            >
                                <Typography gutterBottom variant="h5">
                                    Joint
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={3} direction="row" justifyContent="center" alignItems="center">
                    {/* Company */}
                    <Card
                        key='company-account'
                        onClick={(event) => handleListItemClick(event, ACCOUNT_TYPE.COMPANY)}
                    >
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="Company"
                                image="/images/enterprise.png"
                                title="Company"
                            />
                            <CardContent
                                align="center"
                            >
                                <Typography gutterBottom variant="h5">
                                    Company
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid container item xs={12} sm={3} direction="row" justifyContent="center" alignItems="center">
                    {/* Trust */}
                    <Card
                        key='trust-account'
                        onClick={(event) => handleListItemClick(event, ACCOUNT_TYPE.TRUST)}
                    >
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="Company"
                                image="/images/man.png"
                                title="Company"
                            />
                            <CardContent
                                align="center"
                            >
                                <Typography gutterBottom variant="h5">
                                    Trust
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </div>
    }

    const backChooseAccountType = () => {
        dataStorage.accountType = null
        dataStorage.equixId = null
        dataStorage.indexApplicant = 0
        dataStorage.isSubApplicant = false
        dataStorage.accountStatus = ''
        setAccountType(null)
    }

    if (accountType) {
        switch (accountType) {
            case ACCOUNT_TYPE.INDIVIDUAL:
                return <Individual backChooseAccountType={backChooseAccountType} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.JOINT:
                return <Joint backChooseAccountType={backChooseAccountType} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.COMPANY:
                return <Company backChooseAccountType={backChooseAccountType} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.TRUST:
                return <Trust backChooseAccountType={backChooseAccountType} backChooseDraft={backChooseDraft} />
            default:
                return <React.Fragment />
        }
    }

    return (
        <React.Fragment>
            {renderChooseAccountType()}
        </React.Fragment>
    );
}
