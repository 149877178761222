import { ACCOUNT_TYPE } from '@Common/constants';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
    tos_ip: '',
    tos_user_agent: '',
    account_type: ACCOUNT_TYPE.INDIVIDUAL,
    send_registration_email: true,
    new_cma: true,
    bank_method: '',
    bank_account_type: '',
    tradeable_products: {
        equity: ''
    },
    trade_confirmations: [{
        email: '',
        client_address: false
    }],
    branch: { value: 'BR00000000', label: 'DEFAULT VETTING RULES' },
    password: '',
    confirmPassword: '',
    applicant_details: [{
        applicant_id: uuidv4(),
        nationality: '',
        tos_consent: true,
        ekyc_aml_consent: false,
        morrison_confirm: false,
        terms_confirm: false,
        quant_edge_privacy_statement_confirm: false,
        macquarie_confirm: false,
        total_confirm: false,
        australian_tax_resident: '',
        tax_exemption: false,
        applicant_email: '',
        same_as_ra: true,
        government_id: {
            type: '',
            state_of_issue: '',
            number: '',
            medicare_card_expiry_date: '',
            medicare_card_colour: '',
            medicare_name_on_card: '',
            medicare_individual_reference_number: ''
        },
        client_address: false,
        equity: '',
        title: '',
        gender: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        relationship_type: '',
        country_of_birth: '',
        occupation_type: '',
        business_owner_trading_name: '',
        abn_acn_registration_number: '',
        business_owner_trading_address_country: '',
        business_owner_trading_address_full_address: '',
        business_owner_trading_address_street_number: '',
        business_owner_trading_address_street_name: '',
        business_owner_trading_address_street_type: '',
        business_owner_trading_address_unit_flat_number: '',
        business_owner_trading_address_postcode: '',
        business_owner_trading_address_state: '',
        business_owner_trading_address_city_suburb: '',
        business_owner_trading_address_address_line_1: '',
        business_owner_trading_address_address_line_2: '',
        occupation_category: '',
        source_of_wealth: '',
        tfn: '',
        tax_exemption_details: '',
        manual_address: false,
        residential_address_street_number: '',
        residential_address_unit_flat_number: '',
        residential_address_postcode: '',
        residential_address_address_line_1: '',
        residential_address_address_line_2: '',
        residential_address_state: '',
        residential_address_street_name: '',
        residential_address_city_suburb: '',
        residential_address_street_type: '',
        residential_address_country: '',
        residential_address_full_address: '',
        applicant_mobile_phone: ''
    }],
    cma_source_of_funds: '',
    cma_source_of_funds_desc: '',
    cma_account_purpose: '',
    cma_account_purpose_desc: '',
    organization_code: '',
    branch_code: '',
    advisor_code: '',
    bank_bsb: '',
    bank_account_name: '',
    bank_account_number: '',
    bank_cmt_provider: '',
    settlement_method: '',
    settlement_existing_hin: '',
    settlement_pid: '',
    settlement_supplementary_reference: ''
};

export default initialValues
