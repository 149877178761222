import React, { useState } from 'react';
import {
    Stepper,
    Paper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import moment from 'moment'
import CryptoJS from 'react-native-crypto-js';
import ApplicationDetails from './Forms/ApplicationDetails';
import AdditionalInformation from './Forms/AdditionalInformation';
import ReviewApplication from './Forms/ReviewApplication';
import ApplicationResult from './Forms/ApplicationResult';

import validationSchema from './FormModels/formValidation';
import formModel from './FormModels/formModels';
import initialValues from './FormModels/formInitial'

import useStyles from '../styles';
import dataStorage from '@s/dataStorage';
import {
    saveDraft,
    clone,
    getMobilePhoneValue,
    getAddressData,
    mapDataAddress,
    mapManualAddress,
    getEnv,
    checkKycVerify,
    checkSubmitted,
    isMorrison,
    scrollToTop
} from '@s/helper/utils';
import { getOpeningAccountUrl, getSessionUrl } from '@s/api/index'
import { postData, putData } from '@s/api/request'
import at from 'lodash/at'
import {
    BANK_ACCOUNT_TYPE,
    CMT_PROVIDER,
    TRANSACTION_TYPE,
    GOVERNMENT_ID_TYPE,
    CMA,
    EKYC_GOVID_STATUS,
    OCCUPATION_TYPE,
    SETTLEMENT_METHOD,
    MEDICARE_CARD_COLOUR,
    ACCOUNT_STATUS_DISPLAY,
    ACCOUNT_STATUS
} from '@Common/constants'
import { v4 as uuidv4 } from 'uuid';
import FocusError from '@Components/FocusError'

const steps = ['Application Details', 'Additional Information', 'Review'];
const { formId, formField } = formModel;

const checkShow = (listDepend, formValues) => {
    if (typeof listDepend === 'object' && Object.keys(listDepend).length) {
        const keys = Object.keys(listDepend)
        for (let index = 0; index < keys.length; index++) {
            const depend = at(formValues, keys[index])[0] ?? {}
            const value = depend.value || depend
            if (value !== listDepend[keys[index]]) return false
        }
    }
    return true
}

export default function Individual(props) {
    const { data = {} } = dataStorage.dicDraft
    let { formData = {}, step = 0 } = data
    if (dataStorage.accountStatus && dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC) step = 2
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(step);
    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const initialData = React.useRef({ ...initialValues, ...formData });
    const onNextStep = React.useRef(null)
    const refNext = React.useRef(null)
    const listStepCanPress = React.useRef({ 0: true, 1: step > 0, 2: step > 1, 3: step > 2 })
    if (!dataStorage.equixId && dataStorage.registerEmail) {
        initialData.current.applicant_details[0].applicant_email = dataStorage.registerEmail
    }
    const applicantDetailRef = React.useRef()

    function _renderStepContent() {
        switch (activeStep) {
            case 0:
                return <ApplicationDetails ref={applicantDetailRef} />;
            case 1:
                return <AdditionalInformation
                    fn={fn => {
                        onNextStep.current = fn.onNext
                    }}
                />;
            case 2:
                return <ReviewApplication />;
            default:
                return <div>Not Found</div>;
        }
    }

    const clearTrashFieldAndMapData = (obj = {}, path = '', formValues) => {
        try {
            Object.keys(obj).forEach(field => {
                const pathField = `${path ? path + '.' : ''}${field}`
                let item = obj[field]
                if (![undefined, null, ''].includes(item)) {
                    if (Array.isArray(item)) {
                        item.forEach((e, i) => {
                            clearTrashFieldAndMapData(e, `${pathField}[${i}]`, e)
                        })
                    } else {
                        if (typeof item === 'object' && Object.keys(item).length && (!Object.prototype.hasOwnProperty.call(item, 'label') && !Object.prototype.hasOwnProperty.call(item, 'value') && !Object.prototype.hasOwnProperty.call(item, 'full_address'))) return clearTrashFieldAndMapData(item, pathField, formValues)
                        if (typeof item === 'object' && Object.keys(item).length && Object.prototype.hasOwnProperty.call(item, 'label') && Object.prototype.hasOwnProperty.call(item, 'value')) {
                            item = item.value
                            obj[field] = item
                        }
                        const fieldModel = at(formField, pathField.replace(/\[\d]/, ''))[0] ?? {}
                        if (fieldModel && fieldModel.dependentShow) {
                            if (!checkShow(fieldModel.dependentShow, formValues)) {
                                delete obj[field]
                            }
                        }
                    }
                } else delete obj[field]
            })
        } catch (error) {
            console.error('clearTrashFieldAndMapData error ', error)
        }
    }

    async function _submitForm(values, actions) {
        const obj = clone(values)
        const sessionId = +new Date()
        const sessionUrl = getSessionUrl(sessionId);
        const { data: getSessonUrl } = await postData(sessionUrl);
        const getHashPassword = CryptoJS.AES.encrypt(obj.password, getSessonUrl.key).toString();
        obj.password = getHashPassword;
        obj.session_id = sessionId + '';
        delete obj.confirmPassword
        clearTrashFieldAndMapData(obj, '', values)

        // get user agent info for compliance
        obj.tos_ip = window.ipPublic;
        obj.tos_user_agent = navigator.userAgent

        // handler applicant details
        obj.trade_confirmations.length = obj.applicant_details.length
        const listAddressId = []
        if (!obj.applicant_details[0].manual_address) {
            listAddressId.push(obj.applicant_details[0].residential_address_full_address?.id)
        }
        if (obj.applicant_details[0].occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
            listAddressId.push(obj.applicant_details[0].business_owner_trading_address_full_address?.id)
        }
        if (listAddressId.length) {
            await getAddressData(listAddressId)
        }
        obj.applicant_details.forEach((e, i) => {
            if (!e.applicant_id) e.applicant_id = uuidv4()
            // trade_confirmations
            e.applicant_email = e.applicant_email?.trim()
            if (!obj.trade_confirmations[i]) obj.trade_confirmations[i] = {}
            obj.trade_confirmations[i].method = 'EMAIL';
            obj.trade_confirmations[i].email = e.applicant_email;
            obj.trade_confirmations[i].client_address = e.client_address;
            delete e.client_address
            delete e.total_confirm
            delete e.morrison_confirm
            delete e.terms_confirm
            delete e.quant_edge_privacy_statement_confirm
            delete e.macquarie_confirm
            delete e.financial_services_guide_confirm
            delete e.verification_id
            delete e.ekyc_overall_status

            // handle tax
            if (!e.australian_tax_resident) e.tax_exemption = false

            // handle government id
            if (e.government_id.type === GOVERNMENT_ID_TYPE.MEDICARE_CARD) {
                e.government_id.medicare_name_on_card = e.first_name + ' ' + e.last_name;
                const expireDate = e.government_id.medicare_card_expiry_date
                if (e.government_id.medicare_card_colour === MEDICARE_CARD_COLOUR.GREEN) {
                    e.government_id.medicare_card_expiry_date = moment(expireDate, moment.ISO_8601).format('MM/YYYY')
                } else {
                    e.government_id.medicare_card_expiry_date = moment(expireDate, moment.ISO_8601).format('DD/MM/YY')
                }
            } else {
                e.government_id.first_name = e.first_name;
                e.government_id.last_name = e.last_name;
            }
            e.government_id = [e.government_id]

            // handle address
            e.same_as_ra = true;
            e.relationship_type = 'OWNER';
            e.residential_address_country = 'AUSTRALIA'
            e.country_of_birth = 'AUSTRALIA'
            if (e.manual_address) {
                mapManualAddress(e, e)
            } else {
                mapDataAddress(e, e.residential_address_full_address?.id, e.same_as_ra, 'residential_address')
            }
            if (e.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
                mapDataAddress(e, e.business_owner_trading_address_full_address?.id, false, 'business_owner_trading_address')
            }
            delete e.manual_address

            e.applicant_mobile_phone = getMobilePhoneValue(e.applicant_mobile_phone)
            e.dob = moment(e.dob, moment.ISO_8601).format('DD/MM/YYYY')
        })

        // new cma account and settlement_method
        obj.new_cma = !obj.use_existing_CMT_acc
        obj.settlement_method = obj.settlement_method ? SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER : SETTLEMENT_METHOD.SPONSORED_NEW_HIN
        obj.settlement_existing_hin && (obj.settlement_existing_hin = +obj.settlement_existing_hin)
        if (obj.new_cma) {
            obj.new_cma = CMA.CREATE_NEW
            obj.bank_account_type = BANK_ACCOUNT_TYPE.BANK_ACCOUNT;
            obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
        } else {
            obj.new_cma = CMA.USE_EXISTING
            obj.bank_account_type = BANK_ACCOUNT_TYPE.LINKED_CMT_CMA;
            obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
        }
        obj.bank_transaction_type = TRANSACTION_TYPE.BOTH

        // delete other fields
        delete obj.use_existing_CMT_acc
        delete obj.submit_time
        delete obj.equix_id

        // link draft id with submit object
        dataStorage.dicDraft.id && (obj.draft_id = dataStorage.dicDraft.id)

        const url = getOpeningAccountUrl(`/individual/${dataStorage.equixId || ''}`)
        postData(url, obj).then((res) => {
            window.onbeforeunload = null; // remove popup close tab
            dataStorage.applicantInfo = res || { ...values }
            actions.setSubmitting(false);
            let id
            if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
                id = dataStorage.listDraft[0]?.id
            } else {
                id = dataStorage.dicDraft?.id
            }
            saveDraft({
                formData: {
                    ...values,
                    equix_id: dataStorage.equixId,
                    submit_time: +new Date(),
                    tos_ip: obj.tos_ip,
                    tos_user_agent: obj.tos_user_agent

                },
                step: activeStep,
                id
            })
            setActiveStep(activeStep + 1);
        }).catch(error => {
            console.log('submit opening account error: ', error)
            dataStorage.showError && dataStorage.showError(error)
            actions.setSubmitting(false);
            // setActiveStep(0);
        })
    }

    const onNext = (values, actions) => {
        const successCb = () => {
            scrollToTop()
            let id
            if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
                id = dataStorage.listDraft[0]?.id
            } else {
                id = dataStorage.dicDraft?.id
            }
            listStepCanPress.current[activeStep + 1] = true
            saveDraft({ formData: values, step: activeStep + 1, id })
            actions.setTouched({});
            actions.setSubmitting(false);
            setActiveStep(activeStep + 1);
        }
        const errorCb = () => {
            actions.setSubmitting(false);
        }
        if (onNextStep.current) {
            onNextStep.current(successCb, errorCb)
        } else successCb()
    }

    const onStepClick = (index) => {
        if (listStepCanPress.current[index]) {
            setActiveStep(index)
        } else {
            if (index < activeStep) {
                _handleBack()
            } else if (index > activeStep) {
                refNext.current && refNext.current.click()
            }
        }
    }

    const onConfirm = async (values, actions) => {
        const data = clone(values)
        const { applicant_details: applicantDetails } = data
        const obj = {
            applicant_id: applicantDetails[0]?.applicant_id || uuidv4(),
            ekyc_aml_consent: applicantDetails[0].ekyc_aml_consent,
            title: applicantDetails[0]?.title?.value,
            first_name: applicantDetails[0]?.first_name,
            // middle_name: 'twopass',
            last_name: applicantDetails[0]?.last_name,
            gender: applicantDetails[0]?.gender?.value,
            nationality: applicantDetails[0]?.nationality?.value,
            occupation_type: applicantDetails[0]?.occupation_type?.value,
            occupation_category: applicantDetails[0]?.occupation_category?.value,
            source_of_wealth: applicantDetails[0]?.source_of_wealth?.value,
            australian_tax_resident: applicantDetails[0]?.australian_tax_resident
        }
        if (applicantDetails[0]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
            obj.business_owner_trading_name = applicantDetails[0]?.business_owner_trading_name
            obj.abn_acn_registration_number = applicantDetails[0]?.abn_acn_registration_number
        }
        if (applicantDetails[0]?.australian_tax_resident) {
            obj.tax_exemption = applicantDetails[0]?.tax_exemption
            if (applicantDetails[0]?.tax_exemption) {
                obj.tax_exemption_details = applicantDetails[0]?.tax_exemption_details?.value
            }
            if (applicantDetails[0]?.tfn) {
                obj.tfn = applicantDetails[0]?.tfn
            }
        } else {
            obj.tax_exemption = false
        }
        if (applicantDetails[0]?.dob) obj.dob = moment(applicantDetails[0]?.dob, moment.ISO_8601).format('DD/MM/YYYY')
        const getTypeGoverment = applicantDetails[0]?.government_id?.type?.value;

        switch (getTypeGoverment) {
            case GOVERNMENT_ID_TYPE.DRIVER_LICENSE:
                obj.government_id = [
                    {
                        type: applicantDetails[0]?.government_id.type?.value,
                        number: applicantDetails[0]?.government_id.number,
                        state_of_issue: applicantDetails[0]?.government_id.state_of_issue?.value,
                        first_name: applicantDetails[0]?.first_name,
                        // middle_name: 'twopass',
                        last_name: applicantDetails[0]?.last_name
                    }
                ]
                break
            case GOVERNMENT_ID_TYPE.PASSPORT:
                obj.government_id = [
                    {
                        type: applicantDetails[0]?.government_id.type?.value,
                        number: applicantDetails[0]?.government_id.number,
                        first_name: applicantDetails[0]?.first_name,
                        // middle_name: 'twopass',
                        last_name: applicantDetails[0]?.last_name
                    }
                ]
                break
            default: break
        }
        obj.relationship_type = 'OWNER'
        obj.residential_address_country = 'AUSTRALIA'
        obj.country_of_birth = 'AUSTRALIA';
        obj.tos_consent = true
        // obj.dob = moment(obj.dob, moment.ISO_8601).format('DD/MM/YYYY')
        obj.same_as_ra = applicantDetails[0]?.same_as_ra;

        obj.applicant_email = applicantDetails[0]?.applicant_email?.trim();
        obj.applicant_mobile_phone = getMobilePhoneValue(applicantDetails[0]?.applicant_mobile_phone);

        // map address
        const listAddress = []
        if (!applicantDetails[0]?.manual_address) {
            listAddress.push(applicantDetails[0]?.residential_address_full_address?.id)
        }
        if (applicantDetails[0]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
            listAddress.push(applicantDetails[0]?.business_owner_trading_address_full_address?.id)
        }
        if (listAddress.length) {
            await getAddressData(listAddress)
        }

        if (applicantDetails[0]?.manual_address) {
            mapManualAddress(obj, applicantDetails[0])
            obj.same_as_ra && mapSameAddress(obj)
        } else if (applicantDetails[0]?.residential_address_full_address?.id) {
            mapDataAddress(obj, applicantDetails[0]?.residential_address_full_address?.id, obj.same_as_ra, 'residential_address')
        }
        if (applicantDetails[0]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
            mapDataAddress(obj, applicantDetails[0]?.business_owner_trading_address_full_address?.id, false, 'business_owner_trading_address')
        }

        const verificationId = values.applicant_details[0]?.verification_id
        if (!verificationId && !dataStorage.isOperatorSupport) obj.draft_id = dataStorage.dicDraft?.id
        const url = getOpeningAccountUrl(`/individual/${dataStorage.equixId || ''}`)
        const requestMethod = verificationId || dataStorage.isOperatorSupport ? putData : postData
        requestMethod(url, obj).then(response => {
            actions.setSubmitting(false);
            const { ekyc_govid_status: ekycGovidStatus, ekyc_overall_status: ekycOverallStatus, verification_id: verificationID } = response.ekyc_status[0];
            verificationID && (values.applicant_details[0].verification_id = verificationID)
            if (response.equix_id) {
                dataStorage.equixId = response.equix_id
                values.equix_id = response.equix_id
            }
            if (checkKycVerify(ekycOverallStatus)) {
                values.applicant_details[0].ekyc_overall_status = ekycOverallStatus
                return onNext(values, actions);
            }
            if (ekycGovidStatus === EKYC_GOVID_STATUS.EKYC_LOCKED_OUT) {
                return dataStorage.showError && dataStorage.showError(`We’re unable to verify your details, please contact ${dataStorage.config.supportEmail} for support`)
            }
            if (!checkKycVerify(ekycOverallStatus)) {
                return dataStorage.showError && dataStorage.showError('We’re unable to verify your details, please make sure your details are correct, Then let Application KYC Edit Detail Page')
            }
        }
        ).catch(error => {
            actions.setSubmitting(false);
            if (error.ekyc_status && error.ekyc_status[0] && error.ekyc_status[0].message) {
                dataStorage.showError && dataStorage.showError(error.ekyc_status[0].message)
            } else {
                dataStorage.showError && dataStorage.showError(error)
            }
        })
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else if (activeStep === 0 && !checkKycVerify(values.applicant_details[0].ekyc_overall_status)) {
            onConfirm(values, actions)
        } else {
            onNext(values, actions)
        }
    }

    function _backChooseDraft() {
        props.backChooseDraft && props.backChooseDraft()
    }

    function _handleBack(setFieldValue) {
        const func = () => {
            if (activeStep === 0) {
                // setFieldValue(formField.account_type.name, '')
                props.backChooseAccountType && props.backChooseAccountType()
            } else {
                scrollToTop()
                setActiveStep(activeStep - 1);
            }
        }
        if (activeStep !== 0) {
            func()
        } else {
            applicantDetailRef.current && applicantDetailRef.current.checkBack(func)
        }
    }

    const renderBackDraftButton = () => {
        if (dataStorage.listDraft.length < 2 && dataStorage.userType === 0) return <React.Fragment />
        return (
            <div className={classes.wrapper} align="left">
                <Button
                    onClick={_backChooseDraft}
                    variant="contained"
                    className={classes.button}
                >
                    {'Back to Draft List'}
                </Button>
            </div>
        )
    }

    const checkCanSubmit = (values) => {
        let check = true
        for (let index = 0; index < values.applicant_details.length; index++) {
            const element = values.applicant_details[index];
            const checkTerms = isMorrison() ? !element.terms_confirm : (!element.terms_confirm || !element.morrison_confirm)
            if (!element.client_address || !element.total_confirm || !element.macquarie_confirm || !element.quant_edge_privacy_statement_confirm || checkTerms) {
                check = false
                break
            }
        }
        return !isLastStep || check
    }

    const renderButtons = (isSubmitting, values, setFieldValue, submitForm) => {
        const accountType = dataStorage.accountType
        if (!accountType || checkSubmitted()) {
            return (<div className={classes.buttons}>
                <div className={classes.leftButtons}>
                    {renderBackDraftButton()}
                </div>
            </div>)
        }
        const checkCondition = activeStep === 0 && !checkKycVerify(values.applicant_details[0].ekyc_overall_status)
        const isAccepted = checkCondition ? values.applicant_details[0]?.ekyc_aml_consent : checkCanSubmit(values)
        const isHaveBack = activeStep === 0 && dataStorage.indexApplicant === 0 ? (accountType && !dataStorage.accountStatus) : accountType
        const textSubmit = isLastStep ? 'Submit Application' : (checkCondition ? 'confirm' : 'next');
        return (
            <div className={classes.buttons}>

                {/* Back to Draft Button is on left handside */}
                <div className={classes.leftButtons}>
                    {renderBackDraftButton()}
                </div>
                <div className={classes.rightButtons}>
                    {
                        isHaveBack
                            ? <div className={classes.wrapper}>
                                <Button onClick={() => _handleBack(setFieldValue)} className={classes.button}>
                                    Back
                                </Button>
                            </div>
                            : <React.Fragment />
                    }
                    <div className={classes.wrapper}>
                        <Button
                            ref={refNext}
                            disabled={isSubmitting || !isAccepted}
                            // type="submit"
                            variant="contained"
                            onClick={() => submitForm()}
                            color="primary"
                            className={classes.button}
                        >
                            {textSubmit}
                            {isSubmitting && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper} elevation={15}>

                {activeStep === steps.length
                    ? <ApplicationResult backChooseDraft={_backChooseDraft} />
                    : (
                        <React.Fragment>
                            <Typography id='topIndividual' component="h1" variant="h6" align="center">
                                {dataStorage.accountStatus ? (ACCOUNT_STATUS_DISPLAY[dataStorage.accountStatus] || dataStorage.accountStatus) : 'NEW TRADING ACCOUNT'}
                            </Typography>
                            {/* navigation */}
                            <div className={classes.container}>
                                <Stepper
                                    orientation='vertical'
                                    activeStep={activeStep} className={classes.stepperVertical}>
                                    {steps.map((label, i) => (
                                        <Step key={label} onClick={() => onStepClick(i)} className={listStepCanPress.current[i] ? 'stepActive' : ''}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Stepper
                                    activeStep={activeStep} className={classes.stepperHorizontal}>
                                    {steps.map((label, i) => (
                                        <Step key={label} onClick={() => onStepClick(i)} className={listStepCanPress.current[i] ? 'stepActive' : ''}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div className={classes.formContainer}>
                                    <Formik
                                        initialValues={initialData.current}
                                        validationSchema={currentValidationSchema}
                                        validateOnBlur={true}
                                        validateOnChange={false}
                                        onSubmit={_handleSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, errors, values, submitForm, setTouched }) => {
                                            console.log('YOLO errors: ', errors)
                                            return (
                                                <FocusError>
                                                    <Form id={formId} autoComplete='off'>
                                                        {_renderStepContent()}
                                                        {renderButtons(isSubmitting, values, setFieldValue, submitForm, setTouched)}
                                                    </Form>
                                                </FocusError>
                                            )
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
            </Paper>
        </React.Fragment>
    );
}
