import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Header from '../Header';
import Footer from '../Footer';
import { useStyle } from './styles';
import dataStorage from '../../dataStorage'
import MenuNested from '@Components/MenuNested'

export default function MaterialLayout(props) {
  const { children } = props;
  const [mode, setMode] = React.useState(dataStorage.theme);
  const classes = useStyle();
  const [contextMenu, setContextMenu] = React.useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    const XMouse = event.clientX - 2;
    const YMouse = event.clientY + 11

    const XAllow = window.innerWidth - 180;
    const YAllow = window.innerHeight - 100;
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: XAllow > XMouse ? XMouse : XAllow,
          mouseY: YAllow > YMouse ? YMouse : YAllow
        }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const changeTheme = (theme) => {
    dataStorage.theme = theme
    document.body.classList.remove('dark', 'light')
    document.body.classList.add(`${theme}`)
    setMode(theme)
    setContextMenu(null);
  };

  const theme = React.useMemo(() => {
    const themeData = mode === 'light'
      ? createTheme({
        palette: {
          type: 'light',
          verify: {
            verified: '#e0ebb2',
            failed: '#fbd4d9',
            normal: '#E6E7E8'
          },
          table: {
            header: '#f5f5f5'
          },
          checkboxContainer: {
            background: '#eeeeee'
          },
          accordion: {
            expand: '#dff0d8'
          }
        }
      })
      : createTheme({
        palette: {
          type: 'dark',
          verify: {
            verified: '#7ecb20',
            failed: '#cb9ca1',
            normal: '#aeaeae'
          },
          table: {
            header: '#c2c2c2'
          },
          checkboxContainer: {
            background: '#616161'
          },
          accordion: {
            expand: '#e0ebb2'
          }
        }
      })
    return responsiveFontSizes(themeData)
  }, [mode])
  return (
    <ThemeProvider theme={theme}>
      <div id='topMark' />
      <div style={{
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
        backgroundSize: 'cover'
      }} onContextMenu={handleContextMenu}
        onClick={handleClose}
      >
        <CssBaseline />
        <Header />
        <div className={classes.root}>
          {children}
        </div>
        <Footer />
      </div>
      {/* <MenuNested contextMenu={contextMenu} handleClose={handleClose} mode={mode} changeTheme={changeTheme} /> */}
    </ThemeProvider >
  );
}
