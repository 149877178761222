import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';

export default function InputField({ ...props }) {
  const { errorText, helperText, name, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setFieldError, setFieldValue } = useFormikContext();
  function _renderHelperText() {
    if (touched && error) {
      return error;
    } else if (helperText) {
      return helperText
    }
  }
  const onChange = (event) => {
    const getValueField = event.target.value;
    setFieldValue(field.name, getValueField)
    if (rest.type === 'email' && !!error) { // remove old error
      setFieldError(field.name, '')
    }
  }
  return (
    <TextField
      variant="outlined"
      type="text"
      error={touched && error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      onChange={onChange}
      value={field.value || ''}
    />
  );
}
