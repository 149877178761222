import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  appFooter: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    backgroundColor: 'transparent'
  }
}));
