import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
    InputField,
    CheckboxField,
    RadioField,
    SelectField,
    DatePickerField,
    AutocompleteFieldAsync,
    PhoneNumberField
} from '@Components/CustomFields';
import {
    COMPANY_TYPE_OPTIONS,
    COMPANY_SECTOR_OPTIONS,
    TAX_EXEMPTION_DETAILS_OPTIONS
} from '@Common/constants'
import dataStorage from '@s/dataStorage'
import ChessInfo from '@Components/ChessInfo'
import formModel from '../FormModels/formModels';
import { getEnv } from '../../../../helper/utils';

const { formField } = formModel

export default function CompanyDetails() {
    const { values: formValues } = useFormikContext();

    return (
        <Grid container spacing={3} style={{ marginTop: 16 }}>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.company_name.name} label={formField.company_name.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <SelectField required name={formField.company_type.name} label={formField.company_type.label} data={COMPANY_TYPE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <SelectField required name={formField.company_sector.name} label={formField.company_sector.label} data={COMPANY_SECTOR_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.company_acn.name} label={formField.company_acn.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField name={formField.company_abn.name} label={formField.company_abn.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField name={formField.company_tfn.name} label={formField.company_tfn.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <CheckboxField name={formField.company_tax_exemption.name} label={formField.company_tax_exemption.label} fullWidth />
            </Grid>
            {

                formValues.company_tax_exemption
                    ? <Grid item xs={12} sm={12}>
                        <SelectField name={formField.company_tax_exemption_details.name} label={formField.company_tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                    </Grid>
                    : <React.Fragment />
            }
            <Grid item xs={12} sm={12}>
                <DatePickerField
                    name={formField.company_date_of_incorporation.name}
                    label={formField.company_date_of_incorporation.label}
                    required
                    format="dd/MM/yyyy"
                    minDate={new Date('1920/1/1')}
                    maxDate={Date.now()}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.company_nature_of_business_activity.name} label={formField.company_nature_of_business_activity.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <PhoneNumberField required name={formField.company_mobile_phone.name} label={formField.company_mobile_phone.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.company_email.name} label={formField.company_email.label} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <AutocompleteFieldAsync
                    required
                    name={formField.company_registered_office_address_full_address.name}
                    label={formField.company_registered_office_address_full_address.label}
                    fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <CheckboxField name={formField.company_same_as_roa.name} label={formField.company_same_as_roa.label} />
            </Grid>
            {
                formValues.company_same_as_roa
                    ? <React.Fragment />
                    : <Grid item xs={12}>
                        <AutocompleteFieldAsync
                            required
                            name={formField.company_principal_place_of_business_address_full_address.name}
                            label={formField.company_principal_place_of_business_address_full_address.label}
                            fullWidth />
                    </Grid>
            }
        </Grid>
    );
}
