import React from 'react'
import dataStorage from './dataStorage';
import { Helmet } from 'react-helmet-async'

export default function () {
    switch (dataStorage.env) {
        case 'optixtrading': return (
            <Helmet prioritizeSeoTags>
                <title>{'Optix Trading Onboarding Portal'}</title>
                <meta property="og:title" content="Optix Trading Onboarding Portal" />
                <meta name="keywords" content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix" />
                <meta name="author" content="EQUIX" />
                <meta property="og:description" content="Simple eKYC and onboarding for retail and AFSLs" />
                <meta property="og:image" content="https://onboarding.equix.app/preview/optixtrading/preview.png" />
                <meta property="og:url" content="https://onboarding.equix.app" />
                <link rel="icon" href="/optixtrading/favicon.png" />
                <link rel="apple-touch-icon" href="/optixtrading/favicon.png" />
            </Helmet>
        )
        case 'morrison': return (
            <Helmet prioritizeSeoTags>
                <title>{'Morrison Securities Onboarding Portal'}</title>
                <meta property="og:title" content="Morrison Securities Onboarding Portal" />
                <meta name="keywords" content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix" />
                <meta name="author" content="EQUIX" />
                <meta property="og:description" content="Simple eKYC and onboarding for retail and AFSLs" />
                <meta property="og:image" content="https://onboarding.equix.app/preview/equix/preview.png" />
                <meta property="og:url" content="https://onboarding.equix.app" />
                <link rel="icon" href="/morrison/favicon.svg" />
                <link rel="apple-touch-icon" href="/morrison/favicon.svg" />
            </Helmet>
        )
        case 'equitystorytrader': return (
            <Helmet prioritizeSeoTags>
                <title>{'Equity Story Trader Onboarding Portal'}</title>
                <meta property="og:title" content="Morrison Securities Onboarding Portal" />
                <meta name="keywords" content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix" />
                <meta name="author" content="EQUIX" />
                <meta property="og:description" content="Simple eKYC and onboarding for retail and AFSLs" />
                <meta property="og:image" content="https://onboarding.equix.app/preview/equix/preview.png" />
                <meta property="og:url" content="https://onboarding.equix.app" />
                <link rel="icon" href="/equitystorytrader/favicon.svg" />
                <link rel="apple-touch-icon" href="/equitystorytrader/favicon.svg" />
            </Helmet>
        )
        case 'ricard': return (
            <Helmet prioritizeSeoTags>
                <title>{'Shares Exchange Onboarding Portal'}</title>
                <meta property="og:title" content="Morrison Securities Onboarding Portal" />
                <meta name="keywords" content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix" />
                <meta name="author" content="EQUIX" />
                <meta property="og:description" content="Simple eKYC and onboarding for retail and AFSLs" />
                <meta property="og:image" content="https://onboarding.equix.app/preview/equix/preview.png" />
                <meta property="og:url" content="https://onboarding.equix.app" />
                <link rel="icon" href="/ricard/favicon.svg" />
                <link rel="apple-touch-icon" href="/ricard/favicon.svg" />
            </Helmet>
        )
        default: return (
            <Helmet prioritizeSeoTags>
                <title>{'EQUIX Onboarding Portal'}</title>
                <meta property="og:title" content="EQUIX Onboarding Portal" />
                <meta name="keywords" content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix" />
                <meta name="author" content="EQUIX" />
                <meta property="og:description" content="Simple eKYC and onboarding for retail and AFSLs" />
                <meta property="og:image" content="https://onboarding.equix.app/preview/equix/preview.png" />
                <meta property="og:url" content="https://onboarding.equix.app" />
                <link rel="icon" href="/equix/favicon.png" />
                <link rel="apple-touch-icon" href="/equix/favicon.png" />
            </Helmet>
        )
    }
}
