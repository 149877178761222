import React from 'react';
import { Link, Typography } from '@material-ui/core/';
import useStyles from './styles';
import dataStorage from '../../dataStorage';

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.appFooter} align="center">
      <Typography
        variant="body2"
        align="center"
      >
        {dataStorage.config?.footer}
      </Typography>
      <Link color="inherit" href="https://www.quant-edge.com/">
        {dataStorage.config?.copyRight}
      </Link>
    </div>
  );
}
