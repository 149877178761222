import { useEffect } from 'react';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';
import dataStorage from '../dataStorage'

const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0];
    if (isObject(object[firstErrorKey])) {
        return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
    }
    return [...keys, firstErrorKey].join('.');
};

const FormikOnError = ({ children }) => {
    const formik = useFormikContext();

    useEffect(() => {
        if (!formik.isValid && formik.isSubmitting) {
            let firstErrorKey = getFirstErrorKey(formik.errors);
            firstErrorKey = firstErrorKey.replace(/\.(\d)\./, '[$1]')
            const el = document.getElementsByName(firstErrorKey)
            if (el.length) {
                el[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            }
        }
    }, [formik.isValid, formik.isSubmitting]);
    return children;
};

export default FormikOnError;
