import React from 'react';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import useStyles from '../styles';
import {
  InputField
} from '@Components/CustomFields';
import { getDraft } from '@s/helper/utils'
import OnboardingFormModel from '../FormModel/onboardingFormModel'
import formInitialValues from '../FormModel/formInitialValues';
import validationSchema from '../FormModel/validationSchema';
import { getSendOTPUrl, verifyOTP } from '@s/api/index'
import { getData, postData } from '@s/api/request'
import dataStorage, { initialStorage } from '@s/dataStorage';
import ChooseAccountType from '../../PageApplication/ChooseAccountType';
import ChooseDraft from '../../PageApplication/ChooseDraft';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';
const { formId, formField } = OnboardingFormModel;

export const PINprops = {
  // className: ReactCodeInput,
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield',
    width: '48px',
    borderRadius: '4px',
    fontSize: '24px',
    height: '56px',
    paddingLeft: '16px',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid darkgrey'
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    MozAppearance: 'textfield',
    width: '48px',
    borderRadius: '4px',
    fontSize: '24px',
    height: '56px',
    paddingLeft: '16px',
    backgroundColor: 'white',
    color: 'red',
    border: '2px solid red'
  }
}

export default function OnboardingPage() {
  dataStorage.userType = 0
  const classes = useStyles();
  const [step, setStep] = React.useState(0)
  const [verified, setVerified] = React.useState()
  const [pinCode, setPinCode] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  // const [btnIsPressed, setBtnIsPressed] = React.useState(false);
  const [invalidPIN, setInvalidPIN] = React.useState(false);
  const { t } = useTranslation();
  const handlePinChange = pinCode => {
    setPinCode(pinCode);
    // setBtnIsPressed(false);
    setInvalidPIN(false);

    // check to go
    if (pinCode.length === 4) {
      const queryBody = {
        user_login_id: userEmail,
        otp: pinCode
      }
      postData(verifyOTP(), queryBody).then(res => {
        const { application_token: token, account_status: status } = res
        dataStorage.accessToken = token
        dataStorage.registerEmail = queryBody.user_login_id;
        getDraft(`?page_size=${20}&page_id=${1}`)
      }).catch(error => {
        dataStorage.showError && dataStorage.showError(error)
        console.error('Error4:', error);
      })
    }
  };

  React.useEffect(() => {
    const goToApp = () => {
      setVerified(true)
    }
    dataStorage.goToApp = goToApp
  }, [])

  function _handleSubmit(values, actions) {
    Object.assign(dataStorage, initialStorage)
    const { email } = values
    if (step) {
      // go to application page
      const queryBody = {
        user_login_id: email,
        otp: pinCode
      }
      postData(verifyOTP(), queryBody).then(res => {
        const { application_token: token, account_status: status } = res
        dataStorage.accessToken = token
        dataStorage.registerEmail = queryBody.user_login_id
        getDraft(`?page_size=${20}&page_id=${1}`).then(() => {
          actions.setSubmitting(false);
        }).catch(() => {
          actions.setSubmitting(false);
        })
      }).catch(error => {
        dataStorage.showError && dataStorage.showError(error)
        actions.setSubmitting(true);
      })
    } else {
      sendOTP(values.email)
        .then(res => {
          if (res.errorCode) {
            dataStorage.showError && dataStorage.showError(res);
            throw Error()
          }
          setUserEmail(values.email)
          setStep(1)
          actions.setTouched({});
          actions.setSubmitting(false);
        })
        // eslint-disable-next-line node/handle-callback-err
        .catch((error) => {
          actions.setTouched({});
          actions.setSubmitting(false);
        })
    }
  }

  const sendOTP = (email) => {
    return new Promise((resolve, reject) => {
      const url = getSendOTPUrl(email)
      getData(url)
        .then(res => {
          if (res && res.errorCode) {
            dataStorage.showError && dataStorage.showError(res)
            reject() // eslint-disable-line
          } else {
            console.log(email)
            console.log('sendOTP success')
            resolve(res)
          }
        })
        .catch(error => {
          console.error('sendOTP failed ', error)
          dataStorage.showError && dataStorage.showError(error)
          reject(error)
        })
    })
  }

  function renderLoginForm(isSubmitting, values) {
    return <React.Fragment>
      {
        !step
          ? <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <InputField required name={formField.email.name} label={formField.email.label} fullWidth />
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                disabled={isSubmitting}
                type='submit'
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Next
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
              <Typography variant='body1' style={{ textAlign: 'center', marginTop: 24 }}>A verification PIN number will be sent to your email address</Typography>
            </Grid>
          </Grid>
          : <Grid container spacing={3}>
            <Grid item xs={12} sm={12} align="center">
              <ReactCodeInput
                id="pinCode"
                type="password"
                name={formField.otp.name}
                className="ReactCodeInput"
                isValid={!invalidPIN}
                fields={4}
                onChange={handlePinChange}
                value={pinCode}
                {...PINprops}
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              {/* If invalid PIN, show it here */}
              {invalidPIN
                ? <Box fontWeight="fontWeightLight" style={{ backgroundColor: 'Crimson' }} color="White" textAlign="center">wrong email code!</Box>
                : null}
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              <ResendButton
                onClick={(e) => {
                  e.stopPropagation()
                  console.log('Click resend')
                  values.email && sendOTP(values.email)
                }}
                className={classes.absoluteButton}
              />
            </Grid>
          </Grid>
      }
    </React.Fragment>
  }

  if (verified) {
    if (dataStorage.listDraft.length === 1) return <ChooseAccountType />
    return <ChooseDraft />
  }

  return (

    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        <div className={classes.cardBox} >
          {/* Display header */}
          <Typography component="div" >
            <Box fontWeight="fontWeightLight" textAlign="center">
            </Box>
            <Box fontWeight="fontWeightLight" fontSize="h4.fontSize" textAlign="center">
              {!step ? 'New Trading Account' : 'Enter Email Code'}
            </Box>
            {!step
              ? <Box fontWeight="fontWeightBold" textAlign="center">
                <br /><br /><br /> </Box>
              : <Box fontWeight="fontWeightBold" textAlign="center"><br />{userEmail}<br /><br /></Box>}
            {/* Display input box */}
          </Typography>
          <React.Fragment>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema[step]}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form id={formId}>
                  {renderLoginForm(isSubmitting, values)}
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </div>
        <div className={classes.cardBox2}>
          {/* <div className={classes.overlayContent}> */}
          <Typography variant='body1'>To register you will need:</Typography>
          <ul>
            <li><Typography variant='body1'>To be 18 years or older and an Australian resident.</Typography></li>
            <li><Typography variant='body1'>Identification documents: Drivers License, Passport or Birth Certificate.</Typography></li>
            <li><Typography variant='body1'>Australian Tax File Number /ABN for businesses (optional).</Typography></li>
            <li><Typography variant='body1'>To open a new CMA account during this application.</Typography></li>
          </ul>
          {/* </div> */}
        </div>
      </Paper>
    </React.Fragment>
  );
}

function ResendButton(props) {
  const [count, setCount] = React.useState()
  const intervalId = React.useRef(null)

  React.useEffect(() => {
    startCount()
    return () => {
      clearInterval(intervalId.current)
    }
  }, [])

  React.useEffect(() => {
    if (count === 0) {
      clearInterval(intervalId.current)
    }
  }, [count])

  const startCount = () => {
    setCount(60)
    intervalId.current && clearInterval(intervalId.current)
    intervalId.current = setInterval(() => {
      setCount(old => old - 1)
    }, 1000);
  }

  const onResend = (e) => {
    if (count) return
    startCount()
    props.onClick && props.onClick(e)
  }

  if (count) return <React.Fragment />

  return (
    <Button
      // color="lightBlue"
      // variant="capital"
      style={{ justifyContent: 'flex-start' }}
      onClick={onResend}
      // disabled={!!count}
      // variant="contained"
      className={props.className}
    >
      {`CLICK HERE TO SEND VERIFICATION CODE AGAIN ${count ? '(' + count + 's)' : ''}`}
    </Button>
  )
}
