import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';

function PhoneField(props) {
  const { label, data, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper;
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const _onChange = (value, details) => {
    const obj = { ...details, value }
    setValueForm(obj)
  }

  return (
    <FormControl {...rest} error={isError}>
      <MuiPhoneNumber
        {...field}
        {...rest}
        error={isError}
        label={label}
        variant="outlined"
        data-cy="user-phone"
        defaultCountry={'au'}
        // onlyCountries={['au']}
        // countryCodeEditable={false}
        // disableCountryCode={true}
        disableDropdown={true}
        value={(selectedValue && selectedValue.value) || selectedValue || ''}
        onChange={_onChange}
      />
      {_renderHelperText()}
    </FormControl>
  );
}

export default PhoneField;
