import React from 'react'
import {
    Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import dataStorage from '@s/dataStorage';
import { scrollToTop } from '../helper/utils'

export const ERROR_MAPPING = {
    OK: {
        message: 'Successfully Submitted.',
        description: 'Successful'
    },
    INVALID_ARGUMENT: {
        message: 'There was a problem with your request. (400) + Error Detail (BE)',
        description: 'Client specified an invalid argument. Check error message and error details for more information.'
    },
    FAILED_PRECONDITION: {
        message: 'There was a problem with your request. (400)',
        description: 'Request can not be executed in the current system state, such as deleting a non-empty directory.'
    },
    OUT_OF_RANGE: {
        message: 'There was a problem with your request. (400)',
        description: 'Client specified an invalid range.'
    },
    UNAUTHENTICATED: {
        message: 'Request not authenticated. (401)',
        description: 'Request not authenticated due to missing, invalid, or expired OAuth token.'
    },
    PERMISSION_DENIED: {
        message: 'Permission denied. (403)',
        description: 'Client does not have sufficient permission. This can happen because the OAuth token does not have the right scopes, the client doesn\'t have permission, or the API has not been enabled.'
    },
    NOT_FOUND: {
        message: 'Request not found. (404)',
        description: 'A specified resource is not found.'
    },
    ABORTED: {
        message: 'Conflict request. (409)',
        description: 'Concurrency conflict, such as read-modify-write conflict.'
    },
    ALREADY_EXISTS: {
        message: 'Request already exists. (409)',
        description: 'The resource that a client tried to create already exists.'
    },
    RESOURCE_EXHAUSTED: {
        message: 'You have sent too many requests. Please try again later. (429)',
        description: 'Either out of resource quota or reaching rate limiting.'
    },
    CANCELLED: {
        message: 'Request cancelled. (499)',
        description: 'Request cancelled by the client.'
    },
    DATA_LOSS: {
        message: 'Unable to handle this request. (500)',
        description: 'Unrecoverable data loss or data corruption. The client should report the error to the user.'
    },
    UNKNOWN: {
        message: 'Unable to handle this request. Please try again later. (500)',
        description: 'Unknown server error. Typically a server bug.'
    },
    INTERNAL: {
        message: 'Internal Error. (500)',
        description: 'Internal server error. Typically a server bug.'
    },
    NOT_IMPLEMENTED: {
        message: 'Server does not recognize this request. (501)',
        description: 'API method not implemented by the server.'
    },
    'N/A': {
        message: 'Bad Gateway. (502)',
        description: 'Network error occurred before reaching the server. Typically a network outage or misconfiguration.'
    },
    UNAVAILABLE: {
        message: 'Service temporarily unavailable. Please try again later. (503)',
        description: 'Service unavailable. Typically the server is down.'
    },
    DEADLINE_EXCEEDED: {
        message: 'Gateway timeout. (504)',
        description: 'Request deadline exceeded. This will happen only if the caller sets a deadline that is shorter than the method\'s default deadline (i.e. requested deadline is not enough for the server to process the request) and the request did not finish within the deadline.'
    },
    2032: {
        message: 'Your username has been temporarily blocked for security reason. Please contact Equix Support.'
    },
    2062: {
        message: 'Incorrect Password or User Login. Please make sure your details are correct and try again'
    },
    OTP_EXPIRED: {
        message: 'Verification Code expired. Please resend code and try again. (2108)'
    },
    INVALID_OTP: {
        message: 'Verification code is invalid. Please try again. (2120)'
    },
    USED_OTP: {
        message: 'Verification code has been used. Please resend code and try again. (2109)'
    },
    OTP_UNAVAILABLE: {
        message: 'You do not have permission to use OTP service. (2100)'
    },
    INVALID_ENVIRONMENT: {
        message: 'Environment invalid. (2300)'
    },
    INVALID_PASSWORD: {
        message: 'There was a problem with your password. (4011)'
    },
    ALREADY_APPROVED_KYC: {
        message: 'Application already approved KYC. (4101)'
    },
    ALREADY_REJECTED: {
        message: 'Application already rejected. (4102)'
    },
    INVALID_APPLICANT: {
        message: 'There was a problem with your applicant. (5001)'
    },
    'INVALID_APPLICATION': {
        message: 'There was a problem with your application. (5002)'
    },
    'INVALID_APPLICATION_STATUS': {
        message: 'There was a problem with your application status. (5003)'
    },
    'INVALID_KYC_STATUS': {
        message: 'There was a problem with your applicant KYC status. (5002)'
    },
    'ALREADY_APPROVED_BANK': {
        message: 'Application already approved bank info. (4103)'
    },
    INVALID_TOKEN: {
        message: 'Incorrect PIN. Please make sure your PIN are correct and try again.'
    }
}

const Error = (props) => {
    const [error, setError] = React.useState('')

    const showError = (errorObj = '') => {
        if (!errorObj) return
        scrollToTop()
        if (typeof errorObj === 'string') {
            setError(errorObj)
            return
        }
        const errorCode = errorObj.error_code || errorObj.errorCode
        const msg = ERROR_MAPPING[errorCode] || { message: errorCode }
        console.log(msg)
        setError(msg.message)
    }

    React.useEffect(() => {
        dataStorage.showError = showError
    }, [])

    return (
        <Snackbar open={!!error} onClose={() => setError('')}
            autoHideDuration={null}
            disableWindowBlurListener={true}
            ClickAwayListenerProps={{
                mouseEvent: 'onClick',
                onClickAway: () => setTimeout(() => setError(''), 3000)
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert elevation={6} variant="filled" severity='error'>
                {error}
            </Alert>
        </Snackbar>
    )
}
export default Error
