import React from 'react';
import useStyles from './styles';
import dataStorage from '@s/dataStorage';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

export default function Header() {
  const classes = useStyles();

  return (
    <div align="center">
      {
        ['UAT', 'DEV'].includes(dataStorage.enviroment)
          ? <AppBar style={{
            background: '#003300',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Toolbar>
              <Typography variant="h6" component="div">
                {`${dataStorage.enviroment} Environment`}
              </Typography>
            </Toolbar>
          </AppBar>
          : <React.Fragment />
      }
      <img className='logoLight' src={dataStorage.config?.logoLight} alt="Logo" width="auto" height="60px" style={{ maxWidth: 700, marginTop: 80, height: 120 }} />
      <img className='logoDark' src={dataStorage.config?.logoDark} alt="Logo" width="auto" height="60px" style={{ maxWidth: 700, marginTop: 80, height: 120 }} />
    </div>
  );
}
