import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  appHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    backgroundColor: 'transparent'
  },
  appbar: {
    background: '#003300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
