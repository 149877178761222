import Yup from '@Common/customYup'
import formModels from './formModels';
import { GOVERNMENT_ID_TYPE, CMA_SOURCE_OF_FUNDS, CMA_ACCOUNT_PURPOSE, USER_TYPE, OCCUPATION_TYPE } from '@Common/constants';
import { isMyApplicant, getEnv } from '@s/helper/utils';
import dataStorage from '@s/dataStorage';
const { formField } = formModels;

const validationSchema = [
    Yup.object().shape({
        [formField.company_name.name]: Yup.mixed().checkRequired('Company Name is required').checkMaxLength(200, 'Company Name'),
        [formField.company_type.name]: Yup.mixed().checkRequired('Company Type is required'),
        [formField.company_sector.name]: Yup.mixed().checkRequired('Company Sector is required'),
        [formField.company_acn.name]: Yup.mixed().checkRequired('Australian Company Number (ACN) is required').checkMaxLength(9, 'Australian Company Number (ACN)'),
        [formField.company_abn.name]: Yup.mixed().checkMaxLength(11, 'Australian Business Number (ABN)'),
        [formField.company_tax_exemption_details.name]: Yup.mixed().when([formField.company_tax_exemption.name], (exemption, schema) => {
            return exemption ? schema.checkRequired('Company Tax Exemption Details is required') : schema;
        }),
        [formField.company_tfn.name]: Yup.string().checkTfn(undefined),
        [formField.company_date_of_incorporation.name]: Yup.mixed().checkRequired('Date of Incorporation/Registration is required').checkMaxDate('Date of Incorporation/Registration invalid'),
        [formField.company_nature_of_business_activity.name]: Yup.mixed().checkRequired('Company Nature of Business Activity is required'),
        [formField.company_mobile_phone.name]: Yup.mixed().checkRequired('Company Mobile Phone is required').checkPhone(undefined),
        [formField.company_email.name]: Yup.string().checkApplicantEmail(),
        [formField.company_registered_office_address_full_address.name]: Yup.mixed().checkRequired('Company Registered Office Address is required'),
        [formField.company_principal_place_of_business_address_full_address.name]: Yup.mixed().when([formField.company_same_as_roa.name], (same, schema) => {
            return same ? schema : schema.checkRequired('Company Principal Place of Business Address is required')
        })
    }),
    Yup.object().shape({
        [formField.organization_code.name]: Yup.mixed().checkRequiredWithUserType(USER_TYPE.BROKER, 'Organization Code is required'),
        [formField.branch_code.name]: Yup.mixed().checkRequiredWithUserType(USER_TYPE.BROKER, 'Branch Code is required'),
        [formField.advisor_code.name]: Yup.mixed().checkRequiredWithUserType(USER_TYPE.BROKER, 'Advisor Code is required'),
        [formField.branch.name]: Yup.mixed().checkRequiredWithUserType(USER_TYPE.BROKER, 'Vetting Rules Group is required'),
        [formField.tradeable_products.name]: Yup.object().shape({
            [formField.tradeable_products.equity.originName]:
                Yup.mixed().checkRequiredWithUserType(USER_TYPE.BROKER, 'Brokerage Schedule is required')
        }),
        [formField.applicant_details.name]: Yup.array().of(Yup.object().shape({
            [formField.applicant_details.title.originName]: Yup.mixed().checkRequired('Title is required'),
            [formField.applicant_details.nationality.originName]: Yup.mixed().checkRequired('Nationality is required'),
            [formField.applicant_details.first_name.originName]: Yup.string()
                .checkRequired('First Name is required')
                .checkLengthName(GOVERNMENT_ID_TYPE.PASSPORT, 30, 'First Name')
                .checkLengthName(GOVERNMENT_ID_TYPE.DRIVER_LICENSE, 20, 'First Name'),
            [formField.applicant_details.last_name.originName]: Yup.string()
                .checkRequired('Last Name is required')
                .checkLengthName(GOVERNMENT_ID_TYPE.PASSPORT, 31, 'Last Name')
                .checkLengthName(GOVERNMENT_ID_TYPE.DRIVER_LICENSE, 40, 'Last Name'),
            [formField.applicant_details.gender.originName]: Yup.mixed().checkRequired('Gender is required'),
            [formField.applicant_details.dob.originName]: Yup.mixed().checkRequired('Date of Birth is required').checkMinAge(18, undefined),
            [formField.applicant_details.applicant_mobile_phone.originName]: Yup.mixed().checkRequired('Mobile Phone is required').checkPhone(undefined),
            [formField.applicant_details.residential_address_full_address.originName]: Yup.mixed().when([formField.applicant_details.manual_address.originName], (isManual, schema) => {
                return isManual ? schema : schema.checkRequired('Address is required')
            }),
            [formField.applicant_details.government_id.name]: Yup.object().when(formField.applicant_details.applicant_email.originName, (email, schema) => {
                return schema.shape({
                    [formField.applicant_details.government_id.type.originName]: Yup.mixed().checkRequired('ID Type is required'),
                    [formField.applicant_details.government_id.number.originName]: Yup.string().when(
                        [formField.applicant_details.government_id.type.originName],
                        (type, schema) => {
                            if (type) {
                                if (type.value === GOVERNMENT_ID_TYPE.PASSPORT) {
                                    return schema.checkPassport(undefined)
                                } else if (type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE) {
                                    return schema.checkDriverLicense(undefined)
                                }
                                return schema.checkMedicareCard(undefined)
                            } else {
                                return schema
                            }
                        }),
                    [formField.applicant_details.government_id.state_of_issue.originName]: Yup.mixed().when(
                        [formField.applicant_details.government_id.type.originName],
                        (type, schema) => {
                            return type && type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                                ? schema.checkRequired('State of Issue is required')
                                : schema;
                        }),
                    [formField.applicant_details.government_id.medicare_card_colour.originName]: Yup.mixed().when(
                        [formField.applicant_details.government_id.type.originName],
                        (type, schema) => {
                            return type && type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                                ? schema.checkRequired('Card Color is required')
                                : schema;
                        }),
                    [formField.applicant_details.government_id.medicare_card_expiry_date.originName]: Yup.string().when(
                        [formField.applicant_details.government_id.type.originName],
                        (type, schema) => {
                            return type && type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                                ? schema.checkExpireDate(undefined)
                                : schema;
                        })
                })
            }),
            [formField.applicant_details.residential_address_unit_flat_number.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkMaxLength(80, 'Unit Flat Number', undefined) : schema;
                }),
            [formField.applicant_details.residential_address_street_number.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkRequired('Street Number is required').checkMaxLength(80, 'Street Number', null) : schema;
                }),
            [formField.applicant_details.residential_address_street_name.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkRequired('Street Name is required').checkMaxLength(80, 'Street Name', undefined) : schema;
                }),
            [formField.applicant_details.residential_address_street_type.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkRequired('Street Type is required') : schema;
                }),
            [formField.applicant_details.residential_address_city_suburb.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkRequired('Suburb is required').checkMaxLength(80, 'Suburb', undefined) : schema;
                }),
            [formField.applicant_details.residential_address_state.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress ? schema.checkRequired('State is required') : schema;
                }),
            [formField.applicant_details.residential_address_postcode.originName]: Yup.mixed().when(
                formField.applicant_details.manual_address.originName, (manualAddress, schema) => {
                    return manualAddress
                        ? schema.checkRequired('Postcode is required')
                            .checkNumber('Postcode must be number')
                            .checkEqualLength(4, 'Postcode', 'Postcode must be 4 digits')
                        : schema
                }),
            [formField.applicant_details.australian_tax_resident.originName]: Yup.mixed().checkRequired('Australian Tax Resident is required'),
            [formField.applicant_details.tfn.originName]: Yup.string().when(formField.applicant_details.australian_tax_resident.originName,
                (tax, schema) => {
                    return tax ? schema.checkTfn(undefined) : schema // eslint-disable-line
                }),
            [formField.applicant_details.tax_exemption_details.originName]: Yup.mixed().when([formField.applicant_details.australian_tax_resident.originName, formField.applicant_details.tax_exemption.originName], (tax, exemption, schema) => {
                return (tax && exemption) ? schema.checkRequired('Tax Exemption Details is required') : schema;
            }),
            [formField.applicant_details.occupation_type.originName]: Yup.mixed().checkRequired('Occupation Type is required'),
            [formField.applicant_details.business_owner_trading_name.originName]: Yup.mixed().when(formField.applicant_details.occupation_type.originName, (occupationType, schema) => {
                return occupationType?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? schema.checkRequired('Trading Name of the business you own is required') : schema;
            }),
            [formField.applicant_details.business_owner_trading_address_full_address.originName]: Yup.mixed().when(formField.applicant_details.occupation_type.originName, (occupationType, schema) => {
                return occupationType?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? schema.checkRequired('Business Address is required') : schema;
            }),
            [formField.applicant_details.abn_acn_registration_number.originName]: Yup.mixed().when(formField.applicant_details.occupation_type.originName, (occupationType, schema) => {
                return occupationType?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? schema.checkMaxLength(11, 'ABN/ACN/Registration Number of The Business', undefined) : schema;
            }),
            [formField.applicant_details.occupation_category.originName]: Yup.mixed().checkRequiredOccupationCategory('Occupation Category is required'),
            [formField.applicant_details.source_of_wealth.originName]: Yup.mixed().checkRequired('Source of Wealth is required'),
            [formField.applicant_details.applicant_email.originName]: Yup.string().checkApplicantEmail()
        }))
    }),
    Yup.object().shape({
        [formField.cma_source_of_funds.name]: Yup.mixed()
            .when('use_existing_CMT_acc', (iUseExist, schema) => {
                return iUseExist ? schema : schema.checkRequired('Source of Funds is required')
            }),
        [formField.cma_source_of_funds_desc.name]: Yup.string()
            .when(['use_existing_CMT_acc', formField.cma_source_of_funds.name], (iUseExist, sourceOfFund, schema) => {
                return !iUseExist && sourceOfFund?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER ? schema.checkRequired('Source of Funds Description is required') : schema
            }),
        [formField.cma_account_purpose.name]: Yup.mixed()
            .when('use_existing_CMT_acc', (iUseExist, schema) => {
                return iUseExist ? schema : schema.checkRequired('Account Purpose is required')
            }),
        [formField.cma_account_purpose_desc.name]: Yup.string()
            .when(['use_existing_CMT_acc', formField.cma_account_purpose.name], (iUseExist, accountPurpose, schema) => {
                return !iUseExist && accountPurpose?.value === CMA_ACCOUNT_PURPOSE[getEnv()].OTHER ? schema.checkRequired('Account Purpose Description is required') : schema
            }),
        [formField.bank_bsb.name]: Yup.mixed()
            .when(['use_existing_CMT_acc'], (cma, schema) => {
                if ([null, undefined].includes(cma)) return schema;
                return cma === true ? schema.checkRequired('BSB is required') : schema;
            }),
        [formField.bank_account_number.name]: Yup.string()
            .when(['use_existing_CMT_acc'], (cma, schema) => {
                if ([null, undefined].includes(cma)) return schema;
                return cma === true ? schema.checkMaxLength(20, 'BSB') : schema;
            }),
        [formField.settlement_method.name]: Yup.mixed().checkRequired('Settlement Method is required'),
        [formField.settlement_existing_hin.name]: Yup.string()
            .when([formField.settlement_method.name], (settlementMethod, schema) => {
                if ([null, undefined].includes(settlementMethod)) return schema
                return settlementMethod
                    ? schema.checkRequired('Existing HIN is required').checkNumber('Existing HIN must be number')
                        .checkMaxLength(10, 'Existing HIN')
                    : schema;
            }),
        [formField.settlement_pid.name]: Yup.mixed()
            .when([formField.settlement_method.name], (settlementMethod, schema) => {
                if ([null, undefined].includes(settlementMethod)) return schema
                return settlementMethod
                    ? schema.checkRequired('Current Broker is required')
                    : schema;
            })
    }),
    Yup.object().shape({
        [formField.password.name]: Yup.string()
            // eslint-disable-next-line no-useless-escape
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z].{7,}$/, 'Your password must include 8-25 characters and contain at least one lowercase, one capital letter and one number')
            .checkRequired('Password is required'),
        [formField.confirmPassword.name]: Yup.string()
            .oneOf([Yup.ref(formField.password.name)], 'Password must be the same!')
            .checkRequired('Confirm Password is required')
    }),
    Yup.object().shape({
    })
];

export default validationSchema
