import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  paper: {
    maxWidth: '808px',
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '400px',
    display: 'flex',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper
  },
  cardBox: {
    width: '50%',
    minHeight: '400px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  },
  cardBox2: {
    width: '50%',
    minHeight: '400px',
    padding: theme.spacing(3),
    backgroundImage: 'url(/images/growth-investment.webp)',
    backgroundSize: 'cover'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(4),
    minWidth: '150px'
    // marginLeft: theme.spacingssss(1),
    // marginRight: theme.spacing(0),
    // padding: theme.spacing(0)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  }
}));
