import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
    InputField,
    CheckboxField,
    RadioField,
    SelectField,
    DatePickerField,
    AutocompleteField
} from '@Components/CustomFields';
import {
    TRUST_TYPE_OPTIONS,
    TRUSTEE_TYPE_OPTIONS,
    TAX_EXEMPTION_DETAILS_OPTIONS
} from '@Common/constants'
import COUNTRIES from '@Common/countries'
import dataStorage from '@s/dataStorage'
import ChessInfo from '@Components/ChessInfo'
import formModel from '../FormModels/formModels';
import { getEnv } from '../../../../helper/utils';

const { formField } = formModel

export default function TrustDetails() {
    const { values: formValues } = useFormikContext();

    return (
        <Grid container spacing={3} style={{ marginTop: 16 }}>
            <Grid item xs={12} sm={12}>
                <RadioField required name={formField.trustee_type.name} label={formField.trustee_type.label} data={TRUSTEE_TYPE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.trust_name.name} label={formField.trust_name.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <SelectField required name={formField.trust_type.name} label={formField.trust_type.label} data={TRUST_TYPE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField name={formField.trust_tfn.name} label={formField.trust_tfn.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <CheckboxField name={formField.trust_tax_exemption.name} label={formField.trust_tax_exemption.label} fullWidth />
            </Grid>
            {

                formValues.trust_tax_exemption
                    ? <Grid item xs={12} sm={12}>
                        <SelectField name={formField.trust_tax_exemption_details.name} label={formField.trust_tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                    </Grid>
                    : <React.Fragment />
            }
            <Grid item xs={12} sm={12}>
                <AutocompleteField required name={formField.trust_country_of_establishment.name} label={formField.trust_country_of_establishment.label} data={COUNTRIES} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField name={formField.trust_abn.name} label={formField.trust_abn.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.trust_asset_source_details.name} label={formField.trust_asset_source_details.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputField required name={formField.trust_activity.name} label={formField.trust_activity.label} fullWidth />
            </Grid>
        </Grid>
    );
}
