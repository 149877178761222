import React, { forwardRef, useImperativeHandle } from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { useFormikContext, FieldArray } from 'formik';
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  PhoneField,
  PhoneNumberField
} from '@Components/CustomFields';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioField from '@Components/CustomFields/RadioField';
import {
  ACCOUNT_TYPE,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  AUSTRALIAN_TAX_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  MEDICARE_CARD_INDIDUAL_NUMBERS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_OPTIONS,
  DEFAULT_APPLICANT
} from '@Common/constants'
import COUNTRIES from '@Common/countries'
import dataStorage from '@s/dataStorage';
import BrokerData from './BrokerData'
import { makeStyles } from '@material-ui/core/styles';
import formModel from '../FormModels/formModels';
import { getEnv, checkKycVerify } from '@s/helper/utils'

const { formField } = formModel

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '400px',
    display: 'flex',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper
  },
  SelectionBox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent'
  },
  cardBox: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  cardBox2: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  cardBox3: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper': {
      '& button': {
        marginRight: 16
      },
      '&:first-child': {
        marginLeft: 0,
        '& button': {
          marginLeft: 0
        }
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

function ApplicationForm(props, ref) {
  const classes = useStyles();
  const [activeIndex, setIndex] = React.useState(0)

  const { values: formValues, errors, isSubmitting, setFieldTouched, setFieldValue } = useFormikContext();
  const isVerified = checkKycVerify(formValues.applicant_details?.[activeIndex]?.ekyc_overall_status)

  React.useEffect(() => {
    const checkNext = () => {
      const errorObj = errors?.applicant_details?.[activeIndex]
      if (!errorObj || Object.keys(errorObj).length === 0) {
        if (activeIndex !== formValues.applicant_details.length - 1) setIndex(old => old + 1)
      }
      setFieldTouched('applicant_details[1].applicant_email', false);
    }
    if (isSubmitting && errors?.applicant_details) {
      checkNext()
    }
  }, [isSubmitting, errors.applicant_details, activeIndex, formValues.applicant_details])

  const checkBack = (cb) => {
    if (activeIndex > 0) setIndex(old => old - 1)
    else cb && cb()
  }

  useImperativeHandle(ref, () => ({
    checkBack
  }))

  const getMatchYear = (year) => {
    if (year == null) return Date.now();

    const ConfigDate = new Date(Date.now());

    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);

    return ConfigDate
  }

  const renderVerification = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField required disabled={isVerified} name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField required disabled={isVerified} name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField required disabled={isVerified} name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} fullWidth />
        </Grid>
        {!(formValues.applicant_details[activeIndex].manual_address)
          ? <React.Fragment>
            <Grid item xs={12} sm={6}>
              <AutocompleteFieldAsync
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_full_address`}
                label={formField.applicant_details.residential_address_full_address.label}
                fullWidth />
            </Grid>
          </React.Fragment>
          : null}
        {formValues.applicant_details[activeIndex].manual_address
          ? <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} name={`applicant_details[${activeIndex}].residential_address_unit_flat_number`} label={formField.applicant_details.residential_address_unit_flat_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_number`} label={formField.applicant_details.residential_address_street_number.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_name`} label={formField.applicant_details.residential_address_street_name.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_type`} label={formField.applicant_details.residential_address_street_type.label} data={STREET_TYPE} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_city_suburb`} label={formField.applicant_details.residential_address_city_suburb.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_state`} label={formField.applicant_details.residential_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_postcode`} label={formField.applicant_details.residential_address_postcode.label} fullWidth />
            </Grid>
          </React.Fragment>
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
          </React.Fragment>}
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.type`} label={formField.applicant_details.government_id.type.label} data={GOVERNMENT_ID_TYPE_OPTIONS} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].government_id.type
            ? <Grid item xs={12} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.number`} label={formField.applicant_details.government_id.number.label[formValues.applicant_details[activeIndex].government_id.type?.value]} fullWidth />
            </Grid>
            : <React.Fragment />
        }
        {/* Using Driver License */}
        {
          formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
            ? <Grid item xs={12} sm={6}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                label={formField.applicant_details.government_id.state_of_issue.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            : null}

        <Grid item xs={12} sm={6}>
          <AutocompleteField disabled={isVerified} required name={`applicant_details[${activeIndex}].nationality`} label={formField.applicant_details.nationality.label} data={COUNTRIES} fullWidth />
        </Grid>
        {
          dataStorage.userType === 0 && activeIndex === 0
            ? <React.Fragment />
            : <Grid item xs={12} sm={6}>
              <InputField disabled={isVerified || dataStorage.isOperatorSupport} required name={`applicant_details[${activeIndex}].applicant_email`} type='email' label={formField.applicant_details.applicant_email.label} fullWidth />
            </Grid>
        }
      </React.Fragment>
    )
  }

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <RadioField disabled={isVerified} required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            format="dd/MM/yyyy"
            minDate={new Date('1920/1/1')}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {formValues.applicant_details[activeIndex].applicant_mobile_phone?.value
            ? <PhoneField disabled={isVerified} required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
            : <PhoneNumberField disabled={isVerified} required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
          }
        </Grid>
      </React.Fragment>
    )
  }

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].source_of_wealth`} label={formField.applicant_details.source_of_wealth.label} data={SOURCE_OF_WEALTH_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_type`} label={formField.applicant_details.occupation_type.label} data={OCCUPATION_TYPE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_category`} label={formField.applicant_details.occupation_category.label} data={OCCUPATION_CATEGORY_OPTIONS[formValues.applicant_details[activeIndex].occupation_type?.value]} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
            ? <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].business_owner_trading_name`} label={formField.applicant_details.business_owner_trading_name.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField disabled={isVerified} type='number' name={`applicant_details[${activeIndex}].abn_acn_registration_number`} label={formField.applicant_details.abn_acn_registration_number.label} fullWidth />
              </Grid>
              <Grid item xs={6} sm={6}>
                <AutocompleteFieldAsync
                  required
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].business_owner_trading_address_full_address`}
                  label={formField.applicant_details.business_owner_trading_address_full_address.label}
                  fullWidth />
              </Grid>
            </React.Fragment>
            : <React.Fragment />
        }
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
        </Grid>
        {formValues.applicant_details[activeIndex].australian_tax_resident
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <RadioField
                  required
                  isBoolean
                  disabled={isVerified}
                  style={{ flexDirection: 'row ' }}
                  name={`applicant_details[${activeIndex}].australian_tax_resident`}
                  label={formField.applicant_details.australian_tax_resident.label}
                  data={AUSTRALIAN_TAX_OPTIONS}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].tfn`}
                  label={formField.applicant_details.tfn.label}
                  helperText={'Note: Quotation of your Australian Tax File Number(s) (TFN) is optional'}
                  fullWidth />
              </Grid>
              {formValues.applicant_details[activeIndex].tax_exemption
                ? <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].tax_exemption`} label={formField.applicant_details.tax_exemption.label} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].tax_exemption_details`} label={formField.applicant_details.tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                  </Grid>
                </React.Fragment>
                : <React.Fragment>
                  <Grid item xs={12} sm={12}>
                    <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].tax_exemption`} label={formField.applicant_details.tax_exemption.label} />
                  </Grid>
                </React.Fragment>}
            </React.Fragment>)
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <RadioField
                required
                isBoolean
                disabled={isVerified}
                style={{ flexDirection: 'row ' }}
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={formField.applicant_details.australian_tax_resident.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth />
            </Grid>
          </React.Fragment>}
      </React.Fragment>
    )
  }

  const renderNote = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICANT DETAILS</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>Please complete the following fields. This information will be used to create your trading account, and for the purpose of conducting an Electronic Verification (EV) check which is required by the AML/CTF (Anti-Money Laundering and Counter-Terrorism Financing) Act.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>* = required field</Typography>
        </Grid>
      </React.Fragment>
    )
  }

  const renderConfirm = () => {
    return (
      <Grid item xs={12} >
        <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].ekyc_aml_consent`}
          label={
            <div>
              <span>I agree with </span>
              <Link className={classes.link} target="_blank" href={`/ekyc-and-aml-consent.pdf?${+new Date()}`}>
                the eKYC & AML Consent
              </Link>
              <span>.</span>
            </div>
          }
        />
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      {renderNote()}
      <BrokerData disabled={isVerified} setFieldValue={setFieldValue} />
      <FieldArray name={formField.applicant_details.name}>
        {() => {
          return formValues.applicant_details.map((e, i) => {
            if (i !== activeIndex) return <React.Fragment key={`applicant_details_${i}`} />
            return (
              <React.Fragment key={`applicant_details_${i}`}>
                {renderVerification()}
                {renderPersonalInfo()}
                {renderFinancialInfo()}
                {renderConfirm()}
              </React.Fragment>
            )
          })
        }}
      </FieldArray>
    </Grid>
  );
}

export default forwardRef(ApplicationForm)
