import React from 'react';
import CustomIcon from './CustomIcon'
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from './HelpIcon'

const useStyles = makeStyles({
    container: {
        padding: '4px 8px',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        alignItems: 'center',
        fontSize: 16
    },
    primary: {
        fontWeight: 'bold'
    }
});

export default function InfoBox({ style = {}, textStyle = {}, icon, img, iconColor, iconStyle = {}, primary = '', secondary = '', helpText, hover = false }) {
    const classes = useStyles()

    return (
        <div className={classes.container} style={style}>
            {
                img
? <img src={img} style={{ width: 21, height: 21 }} alt='' />
                    : <CustomIcon name={icon} color={iconColor} style={iconStyle} />
            }
            <div style={{ width: 8 }} />
            <span style={{
                width: 'max-content',
                ...textStyle
            }}>{primary ? <b>{primary}&nbsp;</b> : ''}{secondary}{helpText ? <HelpIcon tooltip={helpText} hover={hover} style={{ padding: 0, marginLeft: 8 }} /> : null}</span>
        </div>
    )
}
