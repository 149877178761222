import React from 'react';
import {
  Button,
  Box,
  Paper,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import useStyles from '../styles';
import {
  InputField, PasswordField
} from '@Components/CustomFields';
import { onLogin, postPin } from '@s/helper/utils'
import loginFormModel from '../FormModel/loginFormModel'
import formInitialValues from '../FormModel/formInitialValues';
import validationSchema from '../FormModel/validationSchema';
import dataStorage, { initialStorage } from '@s/dataStorage';
import ChooseDraft from '../../PageApplication';
import ReactCodeInput from 'react-code-input';
import { PINprops } from '../../PageOnboarding/Forms/OnboardingPage'
const { formId, formField } = loginFormModel;

export default function LoginPage() {
  dataStorage.userType = 1
  const classes = useStyles();
  const [step, setStep] = React.useState(0)
  const [verified, setVerified] = React.useState()

  React.useEffect(() => {
    const goToApp = () => {
      setVerified(true)
    }
    dataStorage.goToApp = goToApp
  }, [])

  function _handleSubmit(values, actions) {
    const { onboardingusername, password, pin } = values
    Object.assign(dataStorage, initialStorage)
    if (step) {
      postPin(pin)
        .then(res => { })
        .catch(error => {
          dataStorage.showError && dataStorage.showError(error)
          actions.setSubmitting(false);
        })
    } else {
      onLogin(onboardingusername, password).then(() => {
        setStep(1)
        actions.setTouched({});
        actions.setSubmitting(false);
      })
        .catch(error => {
          // const error = 'Incorrect Password or User Login. Please make sure your details are correct and try again'
          actions.setSubmitting(false);
          dataStorage.showError && dataStorage.showError(error)
        })
    }
  }

  if (verified) return <ChooseDraft />

  const handlePinChange = pin => {
    // check to go
    if (pin.length === 6) {
      postPin(pin)
        .then(res => { })
        .catch(error => {
          dataStorage.showError && dataStorage.showError(error)
        })
    }
  };

  function renderLoginForm(step, values) {
    return <React.Fragment>
      {
        step
          ? <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactCodeInput
                id="pinCode"
                type="password"
                name={formField.pin.name}
                className="ReactCodeInput"
                // isValid={!invalidPIN}
                fields={6}
                onChange={handlePinChange}
                value={values.pin}
                {...PINprops}
              />
              {/* <InputField name={formField.pin.name} label={formField.pin.label} fullWidth /> */}
            </Grid>
          </Grid>
          : <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <InputField name={formField.username.name} label={formField.username.label} fullWidth
                autoComplete='onboardingusername'
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <PasswordField name={formField.password.name} label={formField.password.label} fullWidth
              // type="password"
              />
            </Grid>
          </Grid>
      }
    </React.Fragment>
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        <div className={classes.cardBox} >
          <Typography component="div" >
            <Box fontWeight="fontWeightLight" textAlign="center">
            </Box>
            <Box fontWeight="fontWeightBold" textAlign="center">
              <br /> </Box>
          </Typography>
          <React.Fragment>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema[step]}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form id={formId}>
                  <Box fontWeight="fontWeightLight" fontSize="h4.fontSize" textAlign="center">
                    {step ? 'Enter PIN' : 'BROKER PORTAL'}
                  </Box>
                  <Box fontWeight="fontWeightBold" textAlign="center">
                    <br /> </Box>
                  <Box fontWeight="fontWeightLight" style={{ fontSize: '1.2rem' }} textAlign="center">
                    {step ? values.onboardingusername : ''}
                  </Box>
                  <br />
                  {renderLoginForm(step, values)}

                  <div className={classes.buttons}>
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // style={{minWidth: '180px', minHeight: '60px'}}
                        style={step ? { display: 'none' } : {}}
                      >
                        {step ? 'Enter PIN' : 'Login'}
                        {(isSubmitting && !step) && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </div>
        <div className={classes.cardBox2} >
        </div>
      </Paper >
    </React.Fragment >
  );
}
