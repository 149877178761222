import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  paper: {
    maxWidth: '808px',
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    minHeight: '400px',
    display: 'flex',
    overflow: 'hidden',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
    }
  },
  cardBox: {
    width: '100%',
    minHeight: '400px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up(508 + theme.spacing(3) * 2)]: {
      width: '70%',
      minHeight: '400px'
    },
    [theme.breakpoints.up(708 + theme.spacing(3) * 2)]: {
      width: '50%',
      minHeight: '400px'
    }
  },
  cardBox2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
    padding: theme.spacing(3),
    width: '0px',
    minHeight: '400px',
    // padding: theme.spacing(3),
    // backgroundImage: 'url(/images/growth-investment.webp)',
    backgroundPosition: 'center',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    backgroundSize: 'cover',
    [theme.breakpoints.up(508 + theme.spacing(3) * 2)]: {
      width: '30%',
      minHeight: '400px'
    },
    [theme.breakpoints.up(708 + theme.spacing(3) * 2)]: {
      width: '50%',
      minHeight: '400px'
    }
  },
  overlayContent: {
    background: '#ffffff60',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    flex: 1,
    padding: theme.spacing(2),
    height: 'fit-content',
    borderRadius: 4
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    // marginTop: theme.spacing(4),
    // minWidth: '220px',
    // marginLeft: theme.spacingssss(1),
    // marginRight: theme.spacing(0),
    // padding: theme.spacing(0)
  },
  wrapperLeft: {
    margin: theme.spacing(0)
  },
  wrapperRight: {
    margin: theme.spacing(0)
  },
  buttonProgress: {
    position: 'absolute'
  },
  absoluteButtonContainer: {
    position: 'relative'
  },
  absoluteButton: {
    textTransform: 'none',
    marginTop: theme.spacing(0)
    // "&:hover": {
    //   color: 'indigo',
    // },
    // "&:selected": {
    //   color: 'blue',
    //   fontWeight: 'fontWeightMedium',
    // }
  }
}));
