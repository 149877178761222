import React from 'react';
import { Box, CardMedia, Grid, Typography, Link, Button } from '@material-ui/core';
import { ACCOUNT_STATUS } from '@Common/constants'
import { makeStyles } from '@material-ui/core/styles';
import {
  isBroker
} from '@s/helper/utils';

const useStyles = makeStyles(theme => ({
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  },
  resendEmail: {
    marginTop: 5
  },
  buttonProgress: {
    position: 'absolute'
  }
}))

function ApplicationDeleted({ backChooseDraft }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid container xs={12} sm={4} direction="row" justifyContent="center" alignItems="center">
          <CardMedia
            className={classes.bannerSuccessPage}
            component="img"
            alt="Application received"
            image={'/images/work-process3.png'}
            title="Application received"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography component="h1" variant="h5" align="left">
            <Box fontWeight="fontWeightBold">Application Deleted</Box>
          </Typography>
          <Typography variant="subtitle1" align="left">
            <br /><br />
            Your application has been withdrawn. If you think this was a mistake, please contact
            <Link color="secondary" href={`mailto:${dataStorage.config.supportEmail}`}>
              {` ${dataStorage.config.supportEmail}`}
            </Link> for support.
          </Typography>
        </Grid>
        {isBroker()
          ? <Grid item xs={12} sm={12} >
            <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='default' variant="contained" onClick={backChooseDraft}>Back to Application List</Button>
            </Typography>
          </Grid>
          : null}
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationDeleted;
