import dataStorage from '@s/dataStorage'
import {
  getSessionUrl,
  getAuthUrl,
  getRefreshUrl,
  getDecodeUrl,
  getOpeningAccountUrl,
  getBusinessInfoUrl,
  getPerformSearchUrl,
  getMetaDataAddressUrl
} from '@s/api/index'
import { getData, postData, putData } from '@s/api/request'
import CryptoJS from 'react-native-crypto-js';
import {
  DEFAULT_APPLICANT,
  ACCOUNT_TYPE,
  ACCOUNT_STATUS,
  BSB_OPTION,
  LIST_OF_PIDS,
  CMA_SOURCE_OF_FUNDS_OPTIONS,
  CMA_ACCOUNT_PURPOSE_OPTIONS,
  CMA
} from '@Common/constants'
import initialValues from '@Containers/PageApplication/Joint/FormModels/formInitial'
import moment from 'moment'
import 'moment-timezone';

const REFRESH_TIME = 15 * 60 * 1000;

export function setLang(lang) { }
export function setTheme(theme) { }
export function setFontSize(size) { }

export function scrollToTop() {
  const root = document.getElementById('topMark')
  root && root.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
}

export function checkKycVerify(status) {
  const stateSuccessfully = ['EKYC_VERIFIED', 'EKYC_VERIFIED_ADMINS', 'EKYC_VERIFIED_WITH_CHANGES'];
  return stateSuccessfully.includes(status)
}

export function isBroker() {
  return dataStorage.userType === 1
}

export function isOtherApplicant() {
  return dataStorage.isSubApplicant && !isBroker()
}

export function checkSubmitted() {
  const applicant = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex]
  const isSubmitted = applicant && (applicant.applicant_agreement || dataStorage.dicDraft?.data?.isSubmitted)
  if (dataStorage.isOperatorSupport) {
    return (dataStorage.accountStatus && dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC) || isSubmitted
  }
  return dataStorage.accountStatus || isSubmitted
}

export function isMorrison() {
  return dataStorage.env === 'equix' || dataStorage.env === 'morrison'
}

export function getEnv() {
  if (dataStorage.enviroment === 'PROD') return 'PROD'
  return 'DEV'
}

export function getCurrentAestDate(time) {
  const d = new Date()
  const timezone = 10
  const utc = d.getTime() - (d.getTimezoneOffset() * 60000)
  // Current +10 (AEST) time
  const aest = new Date(utc + (3600000 * -timezone))
  // Current +11 (ADST) time
  const adst = new Date(utc + (3600000 * -(timezone + 1)))
  // Set to start of the month DST starts (October)
  // and time of rollover (0200)
  const dstStart = new Date(aest.getFullYear(), (10 - 1), 1, 2, 0, 0, 0)
  // Set to start of the month DST ends (April)
  // and time of rollover (0300)
  const dstEnd = new Date(aest.getFullYear(), (4 - 1), 1, 3, 0, 0, 0);

  // Now, calculate the position of the first Sunday of the start and end month,
  // which is when DST actually starts, or ends.
  // One of 1001 great uses for mod!
  dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7));
  dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDate()) % 7));

  // Now we simply check if the current date is between the two regions
  // If ADST is after the end datetime and AEST is before the start datetime,
  // then DST is not in effect. You have to think about this one a little bit...
  // it matters which clock you check, otherwise you might have incorrect 1 hour
  // edge cases around the start/end datetimes.
  // An alternative and perhaps easier way to calculate all this would be to just
  // work everything out against UTC and do away with the need for two Dates.
  // 11:15:03 23/Nov/2021 Australian Eastern Standard Time
  if ((adst.getTime() > dstEnd.getTime()) && (aest.getTime() < dstStart.getTime())) {
    // No DST!
    return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Standard Time`
  }
  // Yes, DST!
  return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Daylight Time`
}

export function getFullAddress(source = {}, prefix = '') {
  if (source.manual_address) {
    const formatUnitNumber = source[`${prefix}_unit_flat_number`] + '/'
    return formatUnitNumber +
      source[`${prefix}_street_number`] + ' ' +
      source[`${prefix}_street_name`] + ' ' +
      source[`${prefix}_street_type`].label + ' ' +
      source[`${prefix}_city_suburb`] + ' ' +
      source[`${prefix}_state`].label + ' ' +
      source[`${prefix}_postcode`]
  } else {
    return source[`${prefix}_full_address`] && source[`${prefix}_full_address`].full_address
  }
}

export function mapManualAddress(dist, source) {
  dist.residential_address_country = 'AUSTRALIA'
  const formatUnitNumber = source?.residential_address_unit_flat_number ? (source?.residential_address_unit_flat_number + '/') : ''
  // Set full address
  dist.residential_address_full_address = formatUnitNumber +
    source?.residential_address_street_number + ' ' +
    source?.residential_address_street_name + ' ' +
    source?.residential_address_street_type?.label + ' ' +
    source?.residential_address_city_suburb + ' ' +
    source?.residential_address_state?.label + ' ' + source?.residential_address_postcode
  // Set address line 1
  dist.residential_address_address_line_1 = formatUnitNumber +
    source?.residential_address_street_number + ' ' +
    source?.residential_address_street_name + ' ' +
    source?.residential_address_street_type?.label + ' '
  source?.residential_address_street_number && (dist.residential_address_street_number = source?.residential_address_street_number)
  source?.residential_address_street_name && (dist.residential_address_street_name = source?.residential_address_street_name)
  source?.residential_address_street_type?.value && (dist.residential_address_street_type = source?.residential_address_street_type?.value)
  source?.residential_address_city_suburb && (dist.residential_address_city_suburb = source?.residential_address_city_suburb)
  source?.residential_address_postcode && (dist.residential_address_postcode = source?.residential_address_postcode)
  source?.residential_address_state?.value && (dist.residential_address_state = source?.residential_address_state?.value)
  source?.residential_address_unit_flat_number?.value && (dist.residential_address_unit_flat_number = source?.residential_address_unit_flat_number?.value)
}

export function mapSameAddress(dist) {
  dist.residential_address_country && (dist.postal_address_country = dist.residential_address_country)
  dist.residential_address_full_address && (dist.postal_address_full_address = dist.residential_address_full_address)
  dist.residential_address_street_number && (dist.postal_address_street_number = dist.residential_address_street_number)
  dist.residential_address_street_name && (dist.postal_address_street_name = dist.residential_address_street_name)
  dist.residential_address_street_type && (dist.postal_address_street_type = dist.residential_address_street_type)
  dist.residential_address_unit_flat_number && (dist.postal_address_unit_flat_number = dist.residential_address_unit_flat_number)
  dist.residential_address_postcode && (dist.postal_address_postcode = dist.residential_address_postcode)
  dist.residential_address_state && (dist.postal_address_state = dist.residential_address_state)
  dist.residential_address_city_suburb && (dist.postal_address_city_suburb = dist.residential_address_city_suburb)
  dist.residential_address_address_line_1 && (dist.postal_address_address_line_1 = dist.residential_address_address_line_1)
  dist.residential_address_address_line_2 && (dist.postal_address_address_line_2 = dist.residential_address_address_line_2)
}

export function mapDataAddress(dist, id, isSame, field) {
  const source = dataStorage.addressData[id]
  if (!source) return
  dist[`${field}_country`] = 'AUSTRALIA'
  source?.unit_flat_number && (dist[`${field}_unit_flat_number`] = source.unit_flat_number)
  source?.full_address && (dist[`${field}_full_address`] = source.full_address)
  source?.street_number && (dist[`${field}_street_number`] = source.street_number)
  source?.street_name && (dist[`${field}_street_name`] = source.street_name)
  source?.street_type && (dist[`${field}_street_type`] = source.street_type)
  source?.city_suburb && (dist[`${field}_city_suburb`] = source.city_suburb)
  source?.postcode && (dist[`${field}_postcode`] = source.postcode)
  source?.state && (dist[`${field}_state`] = source.state)
  source?.address_line_1 && (dist[`${field}_address_line_1`] = source.address_line_1)
  source?.address_line_2 && (dist[`${field}_address_line_2`] = source.address_line_2)
  if (isSame) mapSameAddress(dist)
}

export function getAddressData(listId = []) {
  return new Promise((resolve, reject) => {
    const lstEncrypted = []
    listId.forEach(id => {
      if (!dataStorage.addressData[id]) {
        lstEncrypted.push(encodeURIComponent(CryptoJS.AES.encrypt(id, dataStorage.addressKey).toString()))
      }
    })
    if (!lstEncrypted.length) {
      resolve()
      return
    }
    const metaUrl = getMetaDataAddressUrl(lstEncrypted.join(','));
    getData(metaUrl).then(res => {
      if (res && res.length) {
        res.forEach(item => dataStorage.addressData[item.id] = item);
      }
      resolve()
    }).catch((er) => {
      resolve()
    })
  })
}

export function isMyApplicant(index) {
  return isBroker() || (dataStorage.isSubApplicant || index === 0)
}

export function getFirstField(obj = {}, path = '') {
  const firstField = Object.keys(obj)[0]
  if (firstField && typeof obj[firstField] === 'object') return getFirstField(obj[firstField], firstField)
  else return `${path ? path + '.' : ''}${firstField}`
}

export function autoRefreshToken() {
  if (dataStorage.refreshTokenId) clearInterval(dataStorage.refreshTokenId);
  dataStorage.refreshTokenId = setInterval(refreshAccessToken, REFRESH_TIME)
}

export function refreshAccessToken() {
  console.log(`RENEW TOKEN => SEND REFRESH TOKEN  - ${new Date().getTime()}`);
  const data = {
    refreshToken: dataStorage.tokenRefresh,
    deviceID: dataStorage.deviceId
  }
  postData(getRefreshUrl(), { data })
    .then(res => {
      dataStorage.accessToken = res.accessToken;
      autoRefreshToken()
    })
    .catch(() => {
      console.error('Cannot refresh token. Please relogin')
    })
}

export function formatNumber(number, decimal = 2) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(number);
}

export function addEventListenerWindow() {
  window.onbeforeunload = (ev) => {
    ev.preventDefault();
    ev.returnValue = '';
    return ''
  }
}
export function getWhiteLabel() {
  const dataEnv = dataStorage.env;
  // console.log(dataEnv, 'test1')
  if (!dataEnv) return '';
  switch (dataEnv) {
    case 'dev1': return 'EQUIX'
    case 'morrison': return 'Morrison Securities'
    case 'equitystorytrader': return 'Equity Story Trader'
    case 'ricard': return 'Shares.exchange'
    case 'mps': return 'MPS'
    case 'optixtrading': return 'Optix Trading'
    default: return 'EQUIX'
  }
}

export const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: 24 * 60 * 60 * 1000 * 365 / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
}
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

export function getRelativeTime(d1, d2 = new Date()) {
  const elapsed = d1 - d2

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    if (Math.abs(elapsed) > units[u] || u === 'second') {
      return rtf.format(Math.round(elapsed / units[u]), u)
    }
  }
}

export function getPerformSearch(id, referenceNo) {
  return new Promise((resolve, reject) => {
    getData(`${getBusinessInfoUrl()}/${referenceNo}`)
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export function postPerformSearch(id, acn, country = 'AUSTRALIA') {
  console.log('======> Perform search id: ', id)
  return new Promise(async (resolve, reject) => { // eslint-disable-line
    try {
      const url = getPerformSearchUrl(id)
      const response = await postData(url, {
        acn: (acn + '').replace(/\s/g, ''),
        country
      })
      const { verificationId } = response || {}
      if (!verificationId) reject() // eslint-disable-line
      else {
        getData(`${getBusinessInfoUrl()}/${verificationId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export function checkBusinessNumber(acn, country = 'AU') {
  console.log('======> Business number: ', acn)
  return new Promise(async (resolve, reject) => { // eslint-disable-line
    try {
      const url = getBusinessInfoUrl()
      const response = await postData(url, {
        acn: (acn + '').replace(/\s/g, ''),
        country
      })
      const { verificationId } = response || {}
      if (!verificationId) reject() // eslint-disable-line
      else {
        getData(`${url}/${verificationId}`)
          .then(res => resolve({ ...res, verificationId }))
          .catch(err => reject(err))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export function setBranding() {
  const icon = document.querySelector("link[rel*='icon']")
  const favIcon = dataStorage.config?.favicon
  icon && (icon.href = favIcon)
}

export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizer(string = '') {
  if (typeof string !== 'string') return string // dom in dropdown
  const list = string.toLowerCase().split(' ').map(e => e && capitalizeFirstLetter(e))
  return list.join(' ')
}

export function clone(any) {
  try {
    return any ? JSON.parse(JSON.stringify(any)) : any;
  } catch (error) {
    return any
  }
}

export function booleaner(str) {
  if (typeof str === 'boolean') return str
  return !!(str === 'true')
}

export function getMobilePhoneValue(field) {
  field = (field + '').replace(/\+/g, '');
  return `au|04${field}`
}

export function makeCapitalizeFunc() {
  // eslint-disable-next-line no-extend-native
  String.prototype.toCapitalize = function () {
    return this.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ')
  }
}

export function getListOrganizationCode(obj) {
  return Object.keys(obj).map(e => ({ label: (e + '').toCapitalize(), value: e }))
}

export function getListBrokerScheduleCode(arr) {
  return arr.map(e => ({ label: `${e.Schedule_Code} - ${e.Schedule_Description}`, value: e.Schedule_Code }))
}

export function getListVettingRule(arr) {
  return arr.map(e => ({ label: e.branch_name, value: e.branch_id }))
}

export function getListBranchCode(obj = {}, organizationCode) {
  if (!obj[organizationCode]) return []
  return (obj[organizationCode].branch_code || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

export function getListAdvisorCode(obj = {}, organizationCode, branchCode) {
  if (!obj[organizationCode] || !branchCode) return []
  const dicBranchCode = obj[organizationCode].branch_code.reduce((acc, cur) => {
    acc[cur.code] = cur.advisor_code
    return acc
  }, {})
  return (dicBranchCode[branchCode] || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

export function onLogin(username, password) {
  const sessionId = +new Date()
  const sessionUrl = getSessionUrl(sessionId)
  return new Promise((resolve, reject) => {
    postData(sessionUrl)
      .then(res => {
        const session = res.data
        const data = {
          username: (username + '').trim().toLocaleLowerCase(),
          password: CryptoJS.AES.encrypt(password, session.key).toString(),
          provider: 'paritech',
          storage_token: true,
          session_id: `${sessionId}`
        }
        const authUrl = getAuthUrl()
        postData(authUrl, { data })
          .then(res => {
            if (res.user_type === 'retail') {
              return reject("You do not have permission to use broker's service") // eslint-disable-line
            }
            console.log(`login success with ${username}`)
            dataStorage.refreshToken = res.refreshToken
            dataStorage.deviceId = res.deviceID
            resolve()
          })
          .catch(error => {
            reject(error)
            console.error(`login error with ${username}`)
          });
      })
      .catch(error => {
        reject(error)
        console.error('getSessionUrl failed')
      })
  })
}

export function postPin(pin) { // decode + refresh
  const sessionId = +new Date()
  const sessionUrl = getSessionUrl(sessionId)
  return new Promise((resolve, reject) => {
    postData(sessionUrl)
      .then(res => {
        const session = res.data
        const data = {
          token: dataStorage.refreshToken,
          pin: CryptoJS.AES.encrypt(pin, session.key).toString(),
          session_id: `${sessionId}`
        }
        const decodeUrl = getDecodeUrl()
        postData(decodeUrl, { data })
          .then(res => {
            dataStorage.tokenRefresh = res.token
            autoRefreshToken()
            const data = {
              refreshToken: res.token,
              deviceID: dataStorage.deviceId
            }
            postData(getRefreshUrl(), { data })
              .then(res => {
                dataStorage.accessToken = res.accessToken;
                res.baseUrl && (dataStorage.baseUrl = `https://${res.baseUrl}/${dataStorage.config.version}`)
                getDraft(`?status=not_submitted&page_size=${20}&page_id=${1}`)
                resolve()
              })
              .catch(error => {
                console.error('Post refresh eerror', error)
                reject(error)
              });
          })
          .catch(error => {
            console.error('Post decode error', error)
            reject(error)
          });
      })
      .catch(error => {
        console.error('postPin failed', error)
        reject(error)
      })
  })
}

export function getNewestCompanyInfo() {
  return new Promise((resolve, reject) => {
    dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
    if (dataStorage.accountType.value) dataStorage.accountType = dataStorage.accountType.value
    if (dataStorage.accountType === ACCOUNT_TYPE.COMPANY) {
      const verificationId = dataStorage.dicDraft.data.formData?.verificationId
      const url = getBusinessInfoUrl()
      getData(`${url}/${verificationId}`)
        .then(res => {
          const formData = { ...dataStorage.dicDraft.data.formData, ...res }
          dataStorage.dicDraft.data.formData = formData
          resolve()
        })
        .catch(error => {
          console.error('cannot get newest company verification info')
          reject(error)
        })
    } else {
      resolve()
    }
  })
}

export function getDraftOfRetailByOperator(draftId, email) {
  return new Promise((resolve, reject) => {
    getData(getOpeningAccountUrl(`/retail-draft?draft_id=${draftId}&email=${email}`))
      .then((res) => {
        if (res) {
          dataStorage.listDraft = [{ ...res, id: draftId }]
          dataStorage.dicDraft = dataStorage.listDraft[0]
          dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
          const applicants = dataStorage.dicDraft?.data?.formData?.applicant_details
          applicants && applicants.forEach((e, i) => {
            if (e.applicant_id === dataStorage.applicantId) {
              dataStorage.activeIndex = i || 0
              dataStorage.indexApplicant = i
              if (i > 0) dataStorage.isSubApplicant = true
              if (checkKycVerify(e.ekyc_overall_status)) {
                dataStorage.isSubmitted = true
              }
              getAccountStatus(dataStorage.equixId).then(res => {
                resolve()
              }).catch(err => {
                console.log('get account status error: ', err)
                resolve()
              })
            }
          })
        } else {
          resolve()
        }
      }).catch(error => {
        console.error(`getDraft retail error ${error}`)
        dataStorage.goToApp && dataStorage.goToApp()
        reject(error)
      })
  })
}

export function getAccountStatus(equixId) {
  return new Promise((resolve, reject) => {
    getData(getOpeningAccountUrl(`?equix_id=${equixId}`))
      .then(res => {
        dataStorage.accountStatus = res[0]?.account_status
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getDraft(path, draftId) {
  return new Promise((resolve, reject) => {
    const finish = () => {
      dataStorage.goToApp && dataStorage.goToApp()
      delete dataStorage.goToApp
      resolve()
    }
    getData(getOpeningAccountUrl(`/draft${path || ''}`))
      .then((res) => {
        if (res) {
          if (res.name) {
            dataStorage.listDraft = [{ ...res, id: draftId }]
          } else {
            if (res.data) {
              const { data, ...pagination } = res
              dataStorage.paginateDraft = pagination
              dataStorage.listDraft = Object.keys(data).map(k => ({ id: k, ...data[k] }))
            } else {
              dataStorage.listDraft = Object.keys(res).map(k => ({ id: k, ...res[k] }))
            }
          }
          if (dataStorage.listDraft.length === 1) {
            dataStorage.dicDraft = dataStorage.listDraft[0]
            dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0
            dataStorage.equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            dataStorage.applicantId = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex].applicant_id
            if (dataStorage.activeIndex > 0) dataStorage.isSubApplicant = true
            else dataStorage.isSubApplicant = false
            dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
            const equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            if (equixId && !dataStorage.activeIndex) {
              getAccountStatus(equixId).then(res => {
                finish()
              }).catch(err => {
                console.log('get account status error: ', err)
                finish()
              })
            } else {
              finish()
            }
          } else {
            finish()
          }
        }
      }).catch(error => {
        console.error(`getDraft error ${error}`)
        dataStorage.goToApp && dataStorage.goToApp()
        reject(error)
      })
  })
}

function autoGenNameDraft(obj = {}, index) {
  let { formData: { applicant_details: applicantDetails, account_type: accountType, rootBusiness } } = obj
  if (!applicantDetails || applicantDetails.length === 0) return accountType
  if (index) applicantDetails = [applicantDetails[index]]
  const names = []
  applicantDetails.forEach((e, i) => {
    const { title = {}, first_name: firstName, last_name: lastName } = e
    const titleText = (title.label || title) ? (title.label || title) + ' ' : ''
    const name = (firstName && lastName) ? `${titleText}${firstName} ${lastName}` : `Applicant ${i + 1}`
    names.push(name)
  })
  return names.join(', ')
}

function getApplicantId(obj) {
  if (dataStorage.applicantId) return dataStorage.applicantId
  const { formData, index = 0 } = obj
  return formData?.applicant_details?.[index]?.applicant_id
}

export function removeVietnamese(str) {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, ' ');
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
  str = str.trim();
  // bắt đầu xóa dấu tiếng việt  trong chuỗi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export function saveDraft(obj, cb) {
  obj.updated = +new Date()
  const { id, name, ...rest } = obj
  const requestFunc = id ? putData : postData
  let url = getOpeningAccountUrl('/draft')
  if (id) {
    const applicantId = getApplicantId(obj)
    if (dataStorage.isOperatorSupport && applicantId) {
      url = getOpeningAccountUrl(`/retail-draft?draft_id=${id}&email=${dataStorage.registerEmail}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
    } else {
      if (isOtherApplicant() && applicantId) {
        url = getOpeningAccountUrl(`/draft?draft_id=${id}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
      } else {
        url = getOpeningAccountUrl(`/draft?draft_id=${id}`)
      }
    }
  } else if (dataStorage.isSubApplicant) {
    url = getOpeningAccountUrl(`/draft?equix_id=${dataStorage.equixId}`)
  }
  const sendData = {
    name: autoGenNameDraft(rest, dataStorage.isSubApplicant && !dataStorage.isOperatorSupport ? obj.index : null),
    data: rest
  }
  return requestFunc(url, sendData)
    .then((res) => {
      if (!id) dataStorage.listDraft.push({ ...sendData, id: res.draft_id })
      if (res.draft_id) {
        dataStorage.dicDraft = {
          ...sendData,
          id: res.draft_id
        }
      }
      cb && cb()
      console.log('saveDraft success')
    }).catch(error => {
      console.error(`saveDraft error ${error}`)
    })
}

export function createDraftOtherApplicant(equixId, applicantId, applicantEmail, data) {
  data.updated = +new Date()
  const url = getOpeningAccountUrl(`/draft?equix_id=${equixId}&applicant_id=${applicantId}&applicant_email=${applicantEmail}`)
  const sendData = {
    data,
    name: autoGenNameDraft(data, data.index)
  }
  return postData(url, sendData)
}
