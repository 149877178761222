import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 2)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    // overflow: 'hidden',
    minHeight: '400px',
    padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'space-between'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper:first-child': {
      marginLeft: 0,
      '& button': {
        marginLeft: 0
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  stylelistCheckBox: {
    background: theme.palette.checkboxContainer.background,
    borderRadius: 5,
    margin: 12
    // boxSizing: 'border-box'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      flexDirection: 'column'
    }
  },
  formContainer: {
    display: 'flex',
    flex: 1
  },
  stepperVertical: {
    display: 'block !important',
    padding: theme.spacing(3, 0, 2),
    marginRight: theme.spacing(2),
    '& .MuiStepLabel-label': {
      fontSize: 24
    },
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(3)
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6)
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(12)
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: 40
    },
    '& .MuiStep-root': {
      '& *': {
        cursor: 'pointer'
      }
    }
  },
  stepperHorizontal: {
    '& .MuiStepLabel-label': {
      fontSize: 24
    },
    padding: theme.spacing(3, 0, 2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      display: 'none !important'
    },
    [theme.breakpoints.down(600)]: {
      '& .MuiStepLabel-labelContainer': {
        display: 'none'
      }
    },
    '& .MuiStep-root': {
      '& *': {
        cursor: 'pointer'
      }
    }
  }
}));
