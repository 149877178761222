import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    '& input': {
      paddingLeft: 24
    }
  },
  inputAdornment: {
    position: 'absolute',
    '& p': {
      color: 'inherit'
    }
  }
}))

export default function PhoneNumberField(props) {
  const classes = useStyle()
  const { errorText, name, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const formik = useFormikContext();
  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const onChange = (event) => {
    let value = event.target.value;
    if (value.length > 8) {
      value = value.slice(-8) // forr autofill invalid phone number
    }
    const validateNumber = /^[\d]{0,8}$/;
    if (!validateNumber.test(value)) return;
    formik.setFieldValue(props.name, value);
  }

  const onPaste = (event) => {
    let value = event.target.value;
    if (value.length > 8) value = value.slice(-8) // forr autofill invalid phone number
    const validateNumber = /^[\d]{0,8}$/;
    if (!validateNumber.test(value)) return;
    formik.setFieldValue(props.name, value);
  }

  return (
    <TextField
      variant="outlined"
      type="text"
      error={touched && error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      onChange={onChange}
      onPaste={onPaste}
      className={classes.inputContainer}
      inputProps={{
        maxLength: 8,
        ...rest.inputProps
      }}
      InputProps={{
        startAdornment: <InputAdornment className={classes.inputAdornment} position="start">04</InputAdornment>
      }}

    />
  );
}
