import React from 'react';
import { useFormikContext } from 'formik';
import { Typography } from '@material-ui/core';
import FullApplicationDetailsReview from './FullApplicationDetailsReview';
import FullMainApplicantOther from './fullMainApplicantOther';
import dataStorage from '@s/dataStorage'
import { isOtherApplicant } from '../../../../helper/utils';

export default function ReviewApplication() {
  const { values: formValues } = useFormikContext()

  return (
    <React.Fragment>
      {isOtherApplicant() ? <FullMainApplicantOther formValues={formValues} /> : <FullApplicationDetailsReview formValues={formValues} />}
    </React.Fragment>
  );
}
