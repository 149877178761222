/* eslint-disable camelcase */
/* eslint-disable multiline-ternary */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Grid, Typography, Button, Link, CardMedia } from '@material-ui/core';
import { useFormikContext, FieldArray } from 'formik';
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  PhoneField,
  PhoneNumberField,
  MultipleSelectField
} from '@Components/CustomFields';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioField from '@Components/CustomFields/RadioField';
import {
  AUSTRALIAN_TAX_OPTIONS,
  ACCOUNT_TYPE,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  MEDICARE_CARD_INDIDUAL_NUMBERS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_OPTIONS,
  DEFAULT_APPLICANT,
  ROLE_IN_COMPANY_OPTIONS
} from '@Common/constants'
import moment from 'moment'
import {
  getSubmitPrimaryApplicantUrl,
  getOpeningAccountUrl,
  getSubmitOtherApplicantUrl
} from '@s/api/index'
import { postData, getData, putData } from '@s/api/request'
import {
  saveDraft,
  getAddressData,
  getMobilePhoneValue,
  isMyApplicant,
  mapDataAddress,
  mapSameAddress,
  mapManualAddress,
  clone,
  getEnv,
  checkKycVerify,
  isBroker,
  isOtherApplicant
} from '@s/helper/utils'
import COUNTRIES from '@Common/countries'
import dataStorage from '@s/dataStorage';
import BrokerData from './BrokerData'
import { makeStyles } from '@material-ui/core/styles';
import DeleteApplicationButton from '@Components/DeleteApplicationButton'
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'react-native-crypto-js';
import formModel from '../FormModels/formModels';

const { formField } = formModel

const useStyle = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '400px',
    display: 'flex',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper
  },
  SelectionBox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent'
  },
  cardBox: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  cardBox2: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  cardBox3: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  media: {
    marginTop: '15%',
    // height: '60%',
    marginLeft: '30%',
    width: '40% !important'
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper': {
      '& button': {
        marginRight: 16
      },
      '&:first-child': {
        marginLeft: 0,
        '& button': {
          marginLeft: 0
        }
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  governmentIdImage: {
    objectFit: 'contain',
    position: 'absolute',
    maxWidth: 600,
    top: -24,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0
    }
  },
  link: {
    color: theme.palette.info.dark
  },
  genderContainer: {
    '& .radio-horizontal': {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      '& .radio-vertical': {
        display: 'none'
      },
      '& .radio-horizontal': {
        display: 'flex'
      }
    }
  }
}));

function ApplicationForm(props, ref) {
  const classes = useStyle();
  const { values: formValues, errors, isSubmitting, setFieldValue, setFieldTouched, setSubmitting, submitForm, setTouched, validateForm, touched } = useFormikContext();
  const [activeIndex, setIndex] = React.useState(Math.min(dataStorage.indexApplicant, formValues.applicant_details.length - 1))

  const getMatchYear = (year) => {
    if (year == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);
    return ConfigDate
  }

  useImperativeHandle(ref, () => ({
    checkBack,
    checkNext
  }))

  const onClickApplicant = (i) => {
    if (i === activeIndex) return
    if (i < activeIndex) {
      dataStorage.indexApplicant = i
      setIndex(i)
    } else {
      validateForm().then(async errors => {
        if (errors?.applicant_details?.[activeIndex]) {
          setTouched({ ...touched, ...errors })
        } else {
          if (i - activeIndex > 1) {
            if (errors?.applicant_details?.[activeIndex + 1]) {
              setTouched({ ...touched, ...errors })
              dataStorage.indexApplicant = activeIndex + 1
              setIndex(activeIndex + 1)
            }
          } else {
            dataStorage.indexApplicant = i
            setIndex(i)
          }
        }
      })
    }
  }

  const checkNext = (cb) => {
    validateForm().then(async errors => {
      if (errors?.applicant_details?.[activeIndex]) {
        setTouched({ ...touched, ...errors })
      } else {
        if (activeIndex < formValues.applicant_details.length - 1) {
          const index = activeIndex + 1
          setIndex(index)
          dataStorage.indexApplicant = index
        } else cb && cb()
      }
    })
  }

  const checkBack = (cb) => {
    if (activeIndex > 0) {
      const index = activeIndex - 1
      setIndex(index)
      dataStorage.indexApplicant = index
    } else cb && cb()
  }

  const renderButtons = (actions) => {
    const { applicant_details: applicants, account_type: accountType } = formValues
    const min = 1
    const max = dataStorage.config.maxApplicant
    const num = applicants.length
    return (
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography variant="button" gutterBottom>
          Choose Applicant
        </Typography>
        <div className={classes.buttons}>
          {/* Back to Draft Button is on left handside */}
          <div className={classes.leftButtons}>
            <div className={classes.wrapper}>
              {
                applicants.map((e, i) => {
                  const checkFullName = applicants[i].first_name && applicants[i].last_name;
                  return (
                    <Button variant="contained"
                      key={`applicant_button_${i}`}
                      color={i === activeIndex ? 'primary' : 'default'}
                      onClick={() => onClickApplicant(i)}
                      className={classes.button}>
                      {checkFullName ? `${applicants[i].title ? applicants[i].title.value : ''} 
                    ${applicants[i].first_name} ${applicants[i].last_name}` : `Applicant ${i + 1}`}
                    </Button>
                  )
                }
                )
              }
            </div>
          </div>
          {
            dataStorage.isSubApplicant && !dataStorage.isOperatorSupport && !isBroker()
              ? <React.Fragment />
              : <div className={classes.rightButtons}>
                <div className={classes.wrapper}>
                  {
                    num < max
                      ? <Button variant="contained"
                        onClick={() => actions.push({ ...DEFAULT_APPLICANT, applicant_id: uuidv4() })}
                        className={classes.button}
                        color='primary'>
                        <PersonAddIcon onClick={(event) => { }} />
                      </Button>
                      : <React.Fragment />
                  }
                </div>
                <div className={classes.wrapper}>
                  {
                    num > min && activeIndex !== 0
                      ? <Button
                        variant="contained"
                        onClick={() => {
                          actions.remove(activeIndex)
                          if (activeIndex === formValues.applicant_details.length - 1) {
                            setIndex(activeIndex - 1)
                            dataStorage.indexApplicant = activeIndex - 1
                          }
                        }}
                        className={classes.button}
                        color='secondary'>
                        <DeleteIcon onClick={(event) => { }} />
                      </Button>
                      : <React.Fragment />
                  }
                </div>
              </div>
          }
        </div>
      </Grid>
    )
  }

  const renderVerification = () => {
    let imagePath = ''
    if (formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE) {
      if (formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value) {
        imagePath = `/images/${formValues.applicant_details[activeIndex].government_id.type?.value}_${formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value}.svg`
      }
    } else if (formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.PASSPORT) {
      imagePath = '/images/passport.svg'
    }
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <RadioField required name={`applicant_details[${activeIndex}].government_id.type`} label={formField.applicant_details.government_id.type.label} data={GOVERNMENT_ID_TYPE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            imagePath
              ? <CardMedia
                className={classes.governmentIdImage}
                component="img"
                alt="Id Type Image"
                image={imagePath}
              />
              : <React.Fragment />
          }
        </Grid>
        {
          formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
            ? <React.Fragment>
              <Grid item xs={12} sm={6}>
                <SelectField required name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                  label={formField.applicant_details.government_id.state_of_issue.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </React.Fragment>
            : null}
        {
          formValues.applicant_details[activeIndex].government_id.type
            ? <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField required name={`applicant_details[${activeIndex}].government_id.number`} label={formField.applicant_details.government_id.number.label[formValues.applicant_details[activeIndex].government_id.type?.value]} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </React.Fragment>
            : <React.Fragment />
        }
      </React.Fragment>
    )
  }

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <SelectField required name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField required name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField required name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <MultipleSelectField name={`applicant_details[${activeIndex}].role_in_company`} label={formField.applicant_details.role_in_company.label} data={ROLE_IN_COMPANY_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <DatePickerField
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            format="dd/MM/yyyy"
            minDate={new Date('1920/1/1')}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <AutocompleteField required name={`applicant_details[${activeIndex}].nationality`} label={formField.applicant_details.nationality.label} data={COUNTRIES} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className={classes.genderContainer}>
          <RadioField required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
          <RadioField isHorizontal required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          {formValues.applicant_details[activeIndex].applicant_mobile_phone?.value
            ? <PhoneField required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
            : <PhoneNumberField required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
          }
        </Grid>
        {!(formValues.applicant_details[activeIndex].manual_address)
          ? <React.Fragment>
            <Grid item xs={12}>
              <AutocompleteFieldAsync
                required
                name={`applicant_details[${activeIndex}].residential_address_full_address`}
                label={formField.applicant_details.residential_address_full_address.label}
                fullWidth />
            </Grid>
          </React.Fragment>
          : null}
        {formValues.applicant_details[activeIndex].manual_address
          ? <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField name={`applicant_details[${activeIndex}].residential_address_unit_flat_number`} label={formField.applicant_details.residential_address_unit_flat_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField required name={`applicant_details[${activeIndex}].residential_address_street_number`} label={formField.applicant_details.residential_address_street_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField required name={`applicant_details[${activeIndex}].residential_address_street_name`} label={formField.applicant_details.residential_address_street_name.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField required name={`applicant_details[${activeIndex}].residential_address_street_type`} label={formField.applicant_details.residential_address_street_type.label} data={STREET_TYPE} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField required name={`applicant_details[${activeIndex}].residential_address_city_suburb`} label={formField.applicant_details.residential_address_city_suburb.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField equired name={`applicant_details[${activeIndex}].residential_address_state`} label={formField.applicant_details.residential_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField required name={`applicant_details[${activeIndex}].residential_address_postcode`} label={formField.applicant_details.residential_address_postcode.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </React.Fragment>
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
          </React.Fragment>}
        {
          dataStorage.userType === 0 && activeIndex === 0 && !dataStorage.isSubApplicant
            ? <React.Fragment />
            : <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField required
                  inputProps={{ readOnly: isOtherApplicant() }}
                  type='email'
                  name={`applicant_details[${activeIndex}].applicant_email`}
                  label={formField.applicant_details.applicant_email.label}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        {formValues.applicant_details[activeIndex].australian_tax_resident
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <RadioField
                  required
                  isBoolean
                  name={`applicant_details[${activeIndex}].australian_tax_resident`}
                  label={formField.applicant_details.australian_tax_resident.label}
                  data={AUSTRALIAN_TAX_OPTIONS}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField
                  name={`applicant_details[${activeIndex}].tfn`}
                  label={formField.applicant_details.tfn.label}
                  helperText={'Note: Quotation of your Australian Tax File Number(s) (TFN) is optional'}
                  fullWidth />
              </Grid>
              {formValues.applicant_details[activeIndex].tax_exemption
                ? <React.Fragment>
                  <Grid item xs={12} sm={12}>
                    <CheckboxField name={`applicant_details[${activeIndex}].tax_exemption`} label={formField.applicant_details.tax_exemption.label} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SelectField required name={`applicant_details[${activeIndex}].tax_exemption_details`} label={formField.applicant_details.tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                  </Grid>
                </React.Fragment>
                : <React.Fragment>
                  <Grid item xs={12} sm={12}>
                    <CheckboxField name={`applicant_details[${activeIndex}].tax_exemption`} label={formField.applicant_details.tax_exemption.label} />
                  </Grid>
                </React.Fragment>}
            </React.Fragment>)
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <RadioField
                required
                isBoolean
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={formField.applicant_details.australian_tax_resident.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth />
            </Grid>
          </React.Fragment>}
        <Grid item xs={12} sm={12}>
          <SelectField required name={`applicant_details[${activeIndex}].source_of_wealth`} label={formField.applicant_details.source_of_wealth.label} data={SOURCE_OF_WEALTH_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField required name={`applicant_details[${activeIndex}].occupation_type`} label={formField.applicant_details.occupation_type.label} data={OCCUPATION_TYPE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField required name={`applicant_details[${activeIndex}].occupation_category`} label={formField.applicant_details.occupation_category.label} data={OCCUPATION_CATEGORY_OPTIONS[formValues.applicant_details[activeIndex].occupation_type?.value]} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
            ? <React.Fragment>
              <Grid item xs={12} sm={12}>
                <InputField required name={`applicant_details[${activeIndex}].business_owner_trading_name`} label={formField.applicant_details.business_owner_trading_name.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField type='number' name={`applicant_details[${activeIndex}].abn_acn_registration_number`} label={formField.applicant_details.abn_acn_registration_number.label} fullWidth />
              </Grid>
              <Grid item xs={6} sm={12}>
                <AutocompleteFieldAsync
                  required
                  name={`applicant_details[${activeIndex}].business_owner_trading_address_full_address`}
                  label={formField.applicant_details.business_owner_trading_address_full_address.label}
                  fullWidth />
              </Grid>
            </React.Fragment>
            : <React.Fragment />
        }
      </React.Fragment>
    )
  }

  const renderNote = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICANT DETAILS</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' align='justify'>Please complete the following fields. This information will be used to create your trading account, and for the purpose of conducting an Electronic Verification (EV) check which is required by the AML/CTF (Anti-Money Laundering and Counter-Terrorism Financing) Act.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>* = required field</Typography>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Grid container spacing={3}>
      {renderNote()}
      <FieldArray name={formField.applicant_details.name}>
        {({ push, remove }) => {
          return formValues.applicant_details.map((e, i) => {
            if (i !== activeIndex) return <React.Fragment key={`applicant_details_${i}`} />
            return (
              <React.Fragment key={`applicant_details_${i}`}>
                {renderButtons({ push, remove })}
                {renderPersonalInfo()}
                {renderVerification()}
                {renderFinancialInfo()}
              </React.Fragment>
            )
          })
        }}
      </FieldArray>
    </Grid>
  );
}

export default forwardRef(ApplicationForm)
